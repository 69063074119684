import { Card, Divider, Skeleton, Tooltip } from '@nextui-org/react';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { BsBoxSeamFill } from 'react-icons/bs';
import {
  GiHandTruck,
  GiPathDistance,
  GiWeight,
  GiWeightScale,
} from 'react-icons/gi';
import { MdTimer } from 'react-icons/md';
import { Deposito } from '../../../../interfaces/IUsuario';
import { IMotorista } from '../../../../interfaces/response/Motorista/IMotorista';
import { IVeiculoMotorista } from '../../../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import { formataDuracao } from '../../../../util/format';
import { moneyMask } from '../../../../util/mask';
import { RouteError } from '../../hooks/useCreateRoutes';
import { IPedido } from '../../interfaces/IPedido';
import useCreatedRouteDetails from './hooks/useCreatedRouteDetails';

export interface CreatedRouteDetailProps {
  selectedOrders: IPedido[];
  warehouse: Deposito | undefined;
  backToWarehouse: boolean;
  vehicle?: IVeiculoMotorista;
  driver?: IMotorista;
  onErrors: (errors: Set<RouteError>) => void;
}

const CreatedRouteDetails: FC<CreatedRouteDetailProps> = ({
  selectedOrders,
  warehouse,
  backToWarehouse,
  driver,
  vehicle,
  onErrors,
}): JSX.Element => {
  const {
    loadingDetails,
    numberOfOrders,
    totalRouteDistance,
    totalRouteTime,
    totalValue,
    totalWeight,
    driverMaxNumberOrdersError,
    vehicleWeightExceedError,
    vehicleValueExceedError,
  } = useCreatedRouteDetails({
    selectedOrders,
    warehouse,
    backToWarehouse,
    vehicle,
    driver,
    onErrors,
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col items-end gap-2">
        {/* Vehicle Details */}
        {vehicle !== undefined && (
          <Card className="min-w-[180px] max-w-max gap-2 rounded-md p-2 px-4 text-foreground-700">
            <h1 className="text-sm font-semibold">Veículo</h1>

            <Divider />

            {/* Percentage of Vehicle Weight */}
            <Tooltip
              content={<p>Peso Máximo do Veiculo</p>}
              showArrow
              className="rounded-md"
              offset={24}
              placement="left"
            >
              <div
                className={`flex items-center justify-between gap-3 ${
                  vehicleWeightExceedError ? 'text-red-600' : ''
                }`}
              >
                <GiWeightScale className="text-2xl" />

                <span className="text-base">
                  {`${moneyMask(totalWeight, '', 2)} / ${moneyMask(
                    vehicle.veiculo.peso,
                    '',
                    2
                  )} kg`}
                </span>

                <span>
                  {`(${((totalWeight * 100) / vehicle.veiculo.peso)
                    .toFixed(2)
                    .replace('.', ',')}%)`}
                </span>
              </div>
            </Tooltip>

            {/* Percentage of Vehicle Order Value */}
            <Tooltip
              content={<p>Valor Máximo do Veiculo</p>}
              showArrow
              className="rounded-md"
              offset={24}
              placement="left"
            >
              <div
                className={`flex items-center justify-between gap-3 ${
                  vehicleValueExceedError ? 'text-red-600' : ''
                }`}
              >
                <BiSolidDollarCircle className="text-2xl" />

                <span className="text-base">
                  {`R$ ${moneyMask(totalValue, '', 2)} / ${moneyMask(
                    vehicle.veiculo.valor,
                    '',
                    2
                  )}`}
                </span>

                <span>
                  {`(${((totalValue * 100) / vehicle.veiculo.valor)
                    .toFixed(2)
                    .replace('.', ',')}%)`}
                </span>
              </div>
            </Tooltip>
          </Card>
        )}

        {/* Driver Details */}
        {driver !== undefined && (
          <Card className="min-w-[180px] max-w-max gap-2 rounded-md p-2 px-4 text-foreground-700">
            <h1 className="text-sm font-semibold">Motorista</h1>

            <Divider />

            {/* Percentage of Orders */}
            <Tooltip
              content={<p>Total de pedidos máximo</p>}
              showArrow
              className="rounded-md"
              offset={24}
              placement="left"
            >
              <div
                className={`flex items-center justify-between gap-3 ${
                  driverMaxNumberOrdersError ? 'text-red-600' : ''
                }`}
              >
                <GiHandTruck className="text-2xl" />

                <span className="text-base">
                  {driver.qtdPedidosMaxima === 0
                    ? '--'
                    : `${numberOfOrders} / ${driver.qtdPedidosMaxima}`}
                </span>

                <span>
                  {driver.qtdPedidosMaxima === 0
                    ? '(--%)'
                    : `(${((numberOfOrders * 100) / driver.qtdPedidosMaxima)
                        .toFixed(2)
                        .replace('.', ',')}%)`}
                </span>
              </div>
            </Tooltip>
          </Card>
        )}

        {/* Route Details */}
        <Card className="min-w-[180px] max-w-max gap-2 rounded-md p-2 px-4 text-foreground-700">
          <h1 className="text-sm font-semibold">Rota</h1>

          <Divider />

          {/* Number of Orders */}
          <Tooltip
            content={<p>Total de Volumes</p>}
            showArrow
            className="rounded-md"
            offset={24}
            placement="left"
          >
            <div className="flex items-center justify-between gap-3">
              <BsBoxSeamFill className="text-2xl" />

              <span className="text-base">{numberOfOrders}</span>
            </div>
          </Tooltip>

          {/* Total weight */}
          <Tooltip
            content={<p>Total de Peso</p>}
            showArrow
            className="rounded-md"
            offset={24}
            placement="left"
          >
            <div className="flex items-center justify-between gap-3">
              <GiWeight className="text-2xl" />

              <span className="text-base">
                {moneyMask(totalWeight, '', 2)} kg
              </span>
            </div>
          </Tooltip>

          {/* Total value */}
          <Tooltip
            content={<p>Total de Valor</p>}
            showArrow
            className="rounded-md"
            offset={24}
            placement="left"
          >
            <div className="flex items-center justify-between gap-3">
              <BiSolidDollarCircle className="text-2xl" />

              <span className="text-base">
                {moneyMask(totalValue, 'R$', 2)}
              </span>
            </div>
          </Tooltip>

          {/* Total distance */}
          <Tooltip
            content={<p>Distancia a ser Percorrida</p>}
            showArrow
            className="rounded-md"
            offset={24}
            placement="left"
          >
            <div className="flex items-center justify-between gap-3">
              <GiPathDistance className="text-2xl" />

              <span className="text-base">
                {!loadingDetails ? (
                  `${moneyMask(totalRouteDistance || 0, '', 2)} km`
                ) : (
                  <Skeleton className="h-6 w-24 rounded-md" />
                )}
              </span>
            </div>
          </Tooltip>

          {/* Total travel time */}
          <Tooltip
            content={<p>Tempo total</p>}
            showArrow
            className="rounded-md"
            offset={24}
            placement="left"
          >
            <div className="flex items-center justify-between gap-3">
              <MdTimer className="text-2xl" />

              <span className="text-base">
                {!loadingDetails ? (
                  formataDuracao(totalRouteTime || 0)
                ) : (
                  <Skeleton className="h-6 w-24 rounded-md" />
                )}
              </span>
            </div>
          </Tooltip>
        </Card>
      </div>
    </motion.div>
  );
};

export default CreatedRouteDetails;
