import { Wrapper } from '@googlemaps/react-wrapper';
import { Button, Card, Spacer } from '@nextui-org/react';
import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { IMotorista } from '../../../../../interfaces/response/Motorista/IMotorista';
import { IVeiculo } from '../../../../../interfaces/response/Veiculo/IVeiculo';
import { CreateMap } from '../../../../../layouts/GerirRotasLayout/components/CreateMap/CreateMap';
import { selectUsuario } from '../../../../../redux/features/generalData/generalDataSelectors';
import { formataDuracao, formatarData } from '../../../../../util/format';
import {
  cpfMask,
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../util/mask';
import { IRota } from '../../../../CreateRoute/interfaces/Rota/IRota';
import PedidosTable from '../PedidosTable/PedidosTable';

const CardDetalhePedidos = ({
  rota,
  handleApproveRoute,
  handleRejectRoute,
  loadingApproval,
  loadingReject,
  motorista,
  veiculo,
}: {
  rota: IRota;
  handleApproveRoute: (idRota: number) => Promise<void>;
  loadingApproval: boolean;
  handleRejectRoute: (idRota: number) => Promise<void>;
  loadingReject: boolean;
  veiculo: IVeiculo | undefined;
  motorista: IMotorista | undefined;
}): JSX.Element => {
  const user = useSelector(selectUsuario);

  const getRouteStatusDescription = (status: number): string => {
    switch (status) {
      case 0:
        return 'AGUARDANDO APROVAÇÃO';
      case 1:
        return 'APROVADA';
      default:
        return '';
    }
  };

  return (
    <div className="flex w-full flex-col">
      <Spacer y={2} />

      <div className="flex flex-row items-center justify-between">
        <p className="text-xl font-semibold text-foreground-700">
          {rota.descricao}
        </p>
        <div className="flex gap-1">
          <Button
            onPress={() => handleApproveRoute(rota.idRota)}
            size="sm"
            color="success"
            isLoading={loadingApproval}
            startContent={
              <div>
                <FaCheckCircle size={18} color="#ffffff" />
              </div>
            }
          >
            <p style={{ color: '#fff' }}>Aprovar</p>
          </Button>
          <Button
            onPress={() => handleRejectRoute(rota.idRota)}
            size="sm"
            color="danger"
            isLoading={loadingReject}
            startContent={
              <div>
                <FaTimesCircle size={18} />
              </div>
            }
          >
            <p>Cancelar</p>
          </Button>
        </div>
      </div>

      <Spacer y={2} />

      {/* Rota */}
      {rota && (
        <Card
          className="grid grid-cols-3 gap-4 bg-foreground-100 p-4"
          shadow="none"
        >
          {/* Data Saída */}
          <div>
            <p className="font-semibold">Data de saída:</p>
            <p className="text-default-600">
              {formatarData(rota.dataSaida, true, 'normal')}
            </p>
          </div>

          {/* Pedidos */}
          <div>
            <p className="font-semibold">Pedidos:</p>
            <p className="text-default-600">{rota.pedidos?.length}</p>
          </div>

          {/* Volta para Deposito */}
          <div>
            <p className="font-semibold">Volta CD:</p>
            <p className="text-default-600">
              {rota.voltaParaDeposito ? 'Sim' : 'Não'}
            </p>
          </div>

          {/* Duração Total */}
          <div>
            <p className="font-semibold">Duração Total:</p>
            <p className="text-default-600">
              {formataDuracao(rota.duracaoTotalSegundos) || '00:00:00'}
            </p>
          </div>

          {/* Trajeto Total */}
          <div>
            <p className="font-semibold">Trajeto Total:</p>
            <p className="text-default-600">
              {formatarMedidaGenerico(rota.trajetoTotalMetros, 'Km')}
            </p>
          </div>

          {/* Status */}
          <div>
            <p className="font-semibold">Status:</p>
            <p className="text-default-600">
              {getRouteStatusDescription(rota.status)}
            </p>
          </div>
        </Card>
      )}

      <Spacer y={2} />
      {/* Veiculo Motorista */}
      <div className="flex w-full flex-col gap-4">
        <div className="w-full">
          <div className="flex flex-row items-center justify-between">
            <p className="text-xl font-semibold text-foreground-700">Veículo</p>
          </div>

          {/* Veículo */}
          {rota && (
            <Card
              className="grid grid-cols-3 gap-4 bg-foreground-100 p-4"
              shadow="none"
            >
              {/* Placa */}
              <div>
                <p className="font-semibold">Placa:</p>
                <p className="text-default-600">{veiculo?.placa}</p>
              </div>

              {/* Peso Máximo */}
              <div>
                <p className="font-semibold">Peso Máximo:</p>
                <p className="text-default-600">
                  {formatarMedidaGenerico(veiculo?.peso || 0, 'Kg')}
                </p>
              </div>

              {/* Valor PGR */}
              <div>
                <p className="font-semibold">Valor PGR:</p>
                <p className="text-default-600">
                  {formatarMoeda(veiculo?.valor || 0)}
                </p>
              </div>
            </Card>
          )}
        </div>

        <div className="w-full" style={{ height: '100%' }}>
          <div className="flex flex-row items-center justify-between">
            <p className="text-xl font-semibold text-foreground-700">
              Motorista
            </p>
          </div>

          {/* Motorista */}
          {rota && (
            <Card
              className="grid grid-cols-4 gap-4 bg-foreground-100 p-4"
              shadow="none"
            >
              {/* Nome */}
              <div>
                <p className="font-semibold">Nome:</p>
                <p className="text-small text-default-600">{motorista?.nome}</p>
              </div>

              {/* Pedidos */}
              <div>
                <p className="font-semibold">CPF:</p>
                <p className="text-default-600">
                  {cpfMask(motorista?.cpf || '')}
                </p>
              </div>

              {/* Tipo de Motorista */}
              <div>
                <p className="font-semibold">Tipo de Motorista:</p>
                <p className="text-default-600">
                  {motorista?.idTipoMotorista === 1 ? 'Frota' : 'Agregado'}
                </p>
              </div>

              {/* Qtd de Pedidos Mámixo */}
              <div>
                <p className="font-semibold">QTD Máxima de Pedidos:</p>
                <p className="text-default-600">
                  {motorista?.qtdPedidosMaxima}
                </p>
              </div>
            </Card>
          )}
        </div>
      </div>

      {/* Pedidos Mapa */}
      <Spacer y={4} />

      {rota.pedidos && rota.polylines && (
        <div className="flex w-full flex-row">
          <div className="flex w-full flex-col">
            <div className="flex flex-row items-center justify-between">
              <p className="text-xl font-semibold text-foreground-700">
                Pedidos
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <Wrapper
                apiKey={process.env.REACT_APP_MAP_API_KEY ?? ''}
                libraries={['marker', 'drawing']}
                version="beta"
              >
                {/* Pedidos */}
                <PedidosTable pedidos={rota.pedidos} />
                {user && user.defaultWarehouse && rota.pedidos && (
                  <CreateMap
                    key={rota.idRota}
                    width="35%"
                    height="500px"
                    borderRadius="12px"
                    rotas={[rota]}
                    warehouse={user?.defaultWarehouse}
                    showWaypoints
                  />
                )}
              </Wrapper>
            </div>
          </div>
        </div>
      )}

      <Spacer y={4} />
    </div>
  );
};

export default CardDetalhePedidos;
