import { Button } from '@nextui-org/react';
import React, { useRef } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { IRota } from '../../../../../../CreateRoute/interfaces/Rota/IRota';
import ImpressaoRota from '../../../../../ImpressaoRota/ImpressaoRota';

const PrintButton = ({ route }: { route: IRota }): JSX.Element => {
  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current, // Define o que será impresso
  });

  return (
    <div>
      <Button onClick={handlePrint}>
        <div className="flex flex-row items-center justify-center gap-2">
          <FaDownload className="text text-foreground-700" />
          <span className="text text-foreground-700">Imprimir Manifesto</span>
        </div>
      </Button>

      <div className="hidden">
        <ImpressaoRota ref={printRef} {...route} />
      </div>
    </div>
  );
};

export default PrintButton;
