/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RefreshUserToken, User } from '../../../interfaces/IUsuario';
import { ICheckOrderResponse } from '../../../pages/CreateRoute/interfaces/Rota/ICheckOrderResponse';
import { IRotaDetalhe } from '../../../pages/CreateRoute/interfaces/Rota/IRotaDetalhe';
import { getGeneralData, postLogin, refreshToken } from './generalDataThunk';
import { GeneralDataState } from './generalDataTypes';

const initialState: GeneralDataState = {
  generalData: undefined,
  generalDataStatus: 'idle',
  loading: false,
  auth: 'LoggedOut',
  usuario: undefined,
  errorMessage: undefined,
  ordersDate: {
    startOrdersDate: new Date(),
    endOrdersDate: new Date(),
    searchType: '0',
  },
  routesDate: {
    startRoutesDate: new Date(),
    endRoutesDate: new Date(),
    searchType: '0',
  },
  routeDetails: {
    routeDetails: undefined,
    isLoadingRouteDetails: false,
  },
  returnToWarehouse: true,
  checkedOrders: [] as any,
};

const generalDataSlice = createSlice({
  name: 'generalData',
  initialState,
  reducers: {
    setReturnToWarehouse: (
      state: GeneralDataState,
      action: PayloadAction<boolean>
    ) => {
      state.returnToWarehouse = action.payload;
    },
    setLoading: (state: GeneralDataState) => {
      state.loading = true;
    },
    setLoaded: (state: GeneralDataState) => {
      state.loading = false;
    },
    logOut: (state: GeneralDataState) => {
      state.auth = 'LoggedOut';
      state.usuario = undefined;
    },
    logIn: (state: GeneralDataState) => {
      state.auth = 'LoggedIn';
    },
    setOrdersDate: (
      state: GeneralDataState,
      action: PayloadAction<{
        startOrdersDate: Date;
        endOrdersDate: Date;
        searchType: '0' | '1';
      }>
    ) => {
      state.ordersDate = action.payload;
    },
    setRoutesDate: (
      state: GeneralDataState,
      action: PayloadAction<{
        startRoutesDate: Date;
        endRoutesDate: Date;
        searchType: '0' | '1';
      }>
    ) => {
      state.routesDate = action.payload;
    },
    setRouteDetails: (
      state: GeneralDataState,
      action: PayloadAction<{
        routeDetails: IRotaDetalhe | undefined;
      }>
    ) => {
      state.routeDetails.routeDetails = action.payload.routeDetails;
    },
    setRouteDetailsLoading: (
      state: GeneralDataState,
      action: PayloadAction<{
        loading: boolean;
      }>
    ) => {
      state.routeDetails.isLoadingRouteDetails = action.payload.loading;
    },

    setSelectCheckedOrders: (
      state: GeneralDataState,
      action: PayloadAction<{
        checkedOrder: ICheckOrderResponse;
      }>
    ) => {
      const order = state.checkedOrders.find(
        (orderAux: ICheckOrderResponse) =>
          `${orderAux.rotaPedido.idRotaPedido}${orderAux.rotaPedido.volumesConferidos}` ===
          `${action.payload.checkedOrder.rotaPedido.idRotaPedido}${action.payload.checkedOrder.rotaPedido.volumesConferidos}`
      );

      if (order) {
        const aux = state.checkedOrders;
        state.checkedOrders = aux;
      } else {
        const aux = state.checkedOrders;
        aux.push(action.payload.checkedOrder);
        state.checkedOrders = aux;
      }
    },

    clearSelectCheckedOrders: (state: GeneralDataState) => {
      state.checkedOrders = [];
    },
  },
  extraReducers: (buider) => {
    buider.addCase(
      getGeneralData.fulfilled,
      (state: GeneralDataState, action: PayloadAction<string>) => {
        state.generalDataStatus = 'success';
        state.generalData = action.payload;
      }
    );
    buider.addCase(getGeneralData.rejected, (state: GeneralDataState) => {
      state.generalDataStatus = 'error';
    });
    buider.addCase(getGeneralData.pending, (state: GeneralDataState) => {
      state.generalDataStatus = 'pending';
    });
    buider.addCase(
      refreshToken.fulfilled,
      (state: GeneralDataState, action: PayloadAction<RefreshUserToken>) => {
        state.loading = false;

        if (state.usuario) {
          state.usuario.product = action.payload.product;
          state.usuario.defaultWarehouse = action.payload.defaultWarehouse;
          state.usuario.customerAccess = action.payload.customerAccess;
          state.usuario.accessToken = action.payload.accessToken;
        }
      }
    );
    buider.addCase(
      postLogin.fulfilled,
      (state: GeneralDataState, action: PayloadAction<User>) => {
        state.loading = false;
        state.usuario = action.payload;
      }
    );
    buider.addCase(postLogin.rejected, (state: GeneralDataState, e) => {
      state.loading = false;
      state.errorMessage = e.error.message;
    });
    buider.addCase(postLogin.pending, (state: GeneralDataState) => {
      state.loading = true;
    });
  },
});

export const {
  setLoaded,
  setLoading,
  logOut,
  logIn,
  setOrdersDate,
  setRoutesDate,
  setRouteDetails,
  setRouteDetailsLoading,
  setReturnToWarehouse,
  setSelectCheckedOrders,
  clearSelectCheckedOrders,
} = generalDataSlice.actions;

export default generalDataSlice;
