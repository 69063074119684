/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import {
  Dispatch,
  Key,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Control, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import {
  Theme,
  ThemeContext,
} from '../../../../contexts/ThemeContext/ThemeContext';
import { ObterPedidos } from '../../../../redux/features/clientsData/clientsDataThunk';
import {
  selectOrdersDate,
  selectUsuario,
} from '../../../../redux/features/generalData/generalDataSelectors';
import { setOrdersDate } from '../../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../../redux/store';
import { formatarData } from '../../../../util/format';
import { IPedidoResponse } from '../interfaces/IPedidoResponse';

interface IProps {
  loading: boolean;
  pedidos: IPedidoResponse | undefined;
  control: Control<FormSchema>;
  startDate: Date | undefined;
  endDate: Date | undefined;
  searchType: '0' | '1';
  theme: Theme;
  handleSearchTypeSelected: (key: Iterable<Key>) => void;
  consultarPedidos: () => Promise<void>;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export interface IMessageProps {
  mensagem: string;
  show: boolean;
  type: 'success' | 'error';
}

const formSchema = z.object({
  startDate: z.date().optional(),
  endDate: z.date().optional(),
  searchType: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

const usePedidos = (): IProps => {
  const dispatch = useAsyncDispatch();
  const user = useSelector(selectUsuario);
  const [pedidos, setPedidos] = useState<IPedidoResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { startOrdersDate, endOrdersDate, searchType } =
    useSelector(selectOrdersDate);

  const { theme } = useContext(ThemeContext);
  const { control, watch } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      endDate: new Date(endOrdersDate),
      startDate: new Date(startOrdersDate),
      searchType,
    },
  });
  const { startDate, endDate } = watch();

  const consultarPedidos = async (): Promise<void> => {
    setLoading(true);

    if (startDate && endDate)
      dispatch(
        setOrdersDate({
          startOrdersDate: startDate,
          endOrdersDate: endDate,
          searchType,
        })
      );

    await dispatch(
      ObterPedidos({
        idDeposito: user?.defaultWarehouse.idDeposito.toString() || '',
        dataInicio: formatarData(startDate, true, 'input') ?? '',
        dataFim: formatarData(endDate, true, 'input') ?? '',
        searchType,
        onlyOk: false,
      })
    )
      .unwrap()
      .then((response) => {
        setPedidos(response);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Ocorreu um erro ao consultar os pedidos');
      })
      .finally(() => setLoading(false));
  };

  const handleSearchTypeSelected = (key: Iterable<Key>): void => {
    if (startDate && endDate)
      dispatch(
        setOrdersDate({
          startOrdersDate: startDate,
          endOrdersDate: endDate,
          searchType: Array.from(key)[0] === '0' ? '0' : '1',
        })
      );
  };

  useEffect(() => {
    consultarPedidos();
  }, [startDate, endDate, searchType]);

  return {
    pedidos,
    loading,
    control,
    endDate,
    startDate,
    searchType,
    theme,
    handleSearchTypeSelected,
    consultarPedidos,
    openModal,
    setOpenModal,
  };
};

export default usePedidos;
