import {
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React, { useMemo, useState } from 'react';
import { formatarMedidaGenerico } from '../../../../../../../util/mask';
import { IVolume } from '../../../../interfaces/IVolume';

type Column = {
  key: any;
  name: string;
};

interface TabDetalheVolumesProps {
  volumes: IVolume[] | undefined;
}

const TabDetalheVolumes: React.FC<TabDetalheVolumesProps> = ({ volumes }) => {
  const [sortDescriptor] = useState<SortDescriptor>({
    column: 'Descricao',
    direction: 'ascending',
  });

  const columns: Column[] = [
    {
      key: 'DESCRIÇÃO',
      name: 'DESCRIÇÃO',
    },
    {
      key: 'ALTURA',
      name: 'ALTURA',
    },
    {
      key: 'LARGURA',
      name: 'LARGURA',
    },
    {
      key: 'PROFUNDIDADE',
      name: 'PROFUNDIDADE',
    },
    {
      key: 'PESO',
      name: 'PESO',
    },
  ];

  const cellData = (volume: IVolume | undefined, columnKey: any): any => {
    switch (columnKey) {
      case 'DESCRIÇÃO':
        return volume?.descricao;
      case 'PESO':
        return volume?.peso ? formatarMedidaGenerico(volume?.peso, 'KG') : '';
      case 'ALTURA':
        return volume?.altura
          ? formatarMedidaGenerico(volume?.altura, 'M3')
          : '';
      case 'LARGURA':
        return volume?.largura
          ? formatarMedidaGenerico(volume?.largura, 'M3')
          : '';
      case 'PROFUNDIDADE':
        return volume?.profundidade
          ? formatarMedidaGenerico(volume?.profundidade, 'M3')
          : '';

      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return (
      volumes &&
      [...volumes].sort((a: IVolume, b: IVolume) => {
        const first = cellData(a, sortDescriptor.column as any) as number;
        const second = cellData(b, sortDescriptor.column as any) as number;

        let cmp = 0;
        if (first < second) cmp = -1;
        if (first > second) cmp = 1;

        return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
      })
    );
  }, [sortDescriptor, volumes]);

  return (
    <Table
      aria-label="Tabela de volumes"
      selectionMode="single"
      classNames={{
        wrapper: ['xl:max-h-[60vh]', 'max-h-[50vh]'],
      }}
      sortDescriptor={sortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody items={sortedOrders} emptyContent="Nenhum volume">
        {(item) => (
          <TableRow key={item.idPedido} className="cursor-pointer">
            {(columnKey) => (
              <TableCell align="center">
                <p className="whitespace-nowrap text-foreground-600">
                  {cellData(item, columnKey as any)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TabDetalheVolumes;
