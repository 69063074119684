/* eslint-disable consistent-return */
import React, { FC } from 'react';
import Barcode from 'react-barcode';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { selectUsuario } from '../../../../../../redux/features/generalData/generalDataSelectors';
import { formatarData } from '../../../../../../util/format';
import { ICheckOrderResponse } from '../../../../../CreateRoute/interfaces/Rota/ICheckOrderResponse';

export interface OrderTagProps {
  tagData: ICheckOrderResponse;
}

const OrderTag: FC<OrderTagProps> = ({ tagData }): JSX.Element => {
  const user = useSelector(selectUsuario);

  return (
    <OrderTagContainer>
      <OrderTagContent>
        <OrderTagContentHeader>
          <img
            src={user?.customerAccess.customer.logo || ''}
            alt="logo"
            style={{
              position: 'absolute',
              filter: 'grayscale(100%) invert(70%)',
              width: '100%',
              opacity: 0.1,
              top: '40%',
              rotate: '-25deg',
            }}
          />
          <span
            style={{
              fontWeight: 600,
              fontSize: 20,
              lineHeight: '30px',
            }}
          >
            {tagData.rota?.descricao.toUpperCase()}
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              padding: '8px 12px',
            }}
          >
            <span
              style={{
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '16px',
              }}
            >
              {formatarData(tagData.pedido?.dataPrevisaoEntrega, true)}
            </span>
            <span
              style={{
                fontWeight: 600,
                fontSize: 20,
                lineHeight: '16px',
              }}
            >
              {tagData.rotaPedido.ordem.toString().padStart(2, '0')}
            </span>
          </div>
        </OrderTagContentHeader>
        <OrderTagContentDestinatario>
          <strong>DESTINATÁRIO:</strong>
          <span>{tagData.pedido?.destinatarioNome}</span>
          <strong>ENDEREÇO:</strong>
          <span>
            {tagData.pedido?.destinatarioLogradouro},{' '}
            {tagData.pedido?.destinatarioNumero} -{' '}
            {tagData.pedido?.destinatarioCep}
          </span>
          <span style={{ fontSize: 12 }}>
            {tagData.pedido?.destinatarioCidade} -{' '}
            {tagData.pedido?.destinatarioUf.toUpperCase()}
          </span>
        </OrderTagContentDestinatario>
        <OrderTagFooter>
          <OrderTagFooterContent>
            <OrderTagContentNF>
              <strong>NF-e:</strong>
              <span>{tagData.pedido?.nf}</span>
            </OrderTagContentNF>
            <OrderTagContentVolume>
              <strong>Volume:</strong>
              <span>
                {tagData.rotaPedido.volumesConferidos
                  .toString()
                  .padStart(3, '0')}{' '}
                /{' '}
                {tagData.pedido?.quantidadeVolumes.toString().padStart(3, '0')}
              </span>
            </OrderTagContentVolume>
          </OrderTagFooterContent>
          <OrderTagBarCodeWrapper>
            <Barcode
              value={`${tagData.pedido?.nf.toString()}${tagData.pedido?.serieNf
                .toString()
                .padStart(2, '0')}${tagData.rotaPedido.volumesConferidos
                .toString()
                .padStart(2, '0')}`}
              width={1.2}
              height={40}
              fontSize={12}
              background=""
            />
          </OrderTagBarCodeWrapper>
        </OrderTagFooter>
      </OrderTagContent>
    </OrderTagContainer>
  );
};

const OrderTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  position: relative; /* Para criar o efeito de marca d'água */
`;

const OrderTagContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const OrderTagContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
  border-bottom: 2px solid black;
  background-color: #000;

  span {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
  }
`;
const OrderTagContentRemetente = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-bottom: 2px solid black;

  strong {
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 500;
  }
  span {
    padding: 0px 4px;
    font-size: 14px;
    font-weight: 400;
  }
`;
const OrderTagContentDestinatario = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-bottom: 2px solid black;

  strong {
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 500;
  }
  span {
    padding: 0px 4px;
    font-size: 14px;
    font-weight: 400;
  }
`;

const OrderTagContentVolume = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #000;
  width: 100%;
  height: 50%;

  strong {
    padding: 2px 4px;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
`;
const OrderTagContentNF = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;

  strong {
    padding: 4px;
    font-size: 14px;
    font-weight: 500;
  }
  span {
    padding: 4px;
    font-size: 16px;
    font-weight: 500;
  }
`;

const OrderTagFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const OrderTagFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-right: 2px solid black;
`;

const OrderTagBarCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default OrderTag;
