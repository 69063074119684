/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDisclosure } from '@nextui-org/react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Theme,
  ThemeContext,
} from '../../../../../../contexts/ThemeContext/ThemeContext';
import { Context } from '../../../../../../layouts/GerirRotasLayout/GerirRotasLayout';
import { selectUsuario } from '../../../../../../redux/features/generalData/generalDataSelectors';
import { postRouteFinalize } from '../../../../../../redux/features/gerirRotas/gerirRotasThunk';
import { useAsyncDispatch } from '../../../../../../redux/store';
import { formatarData } from '../../../../../../util/format';
import { converterEbaixarXLSX } from '../../../../../../util/XLSXFactory';
import { IRota } from '../../../../../CreateRoute/interfaces/Rota/IRota';

interface IUseRouteOrderListModal {
  isOpen: boolean;
  onOpenChange: () => void;
  theme: Theme;
  route: IRota | null;
  handleOpenModal: (selectedRoute: IRota) => void;
  handleCloseModal: () => void;
  handleConfirmDelivery: () => Promise<void>;
  loadingAction: boolean;
  handleExportXLSX: (rota: IRota) => void;
  onSelectionChange: (keys: number[] | 'all') => Promise<void>;
  selectedRows: number[];
  setTipoOcorrencia: Dispatch<SetStateAction<IOcorrencia | undefined>>;
  tipoOcorrencia: IOcorrencia | undefined;
  tiposOcorrencia: IOcorrencia[];
  ocorrenciaPersonalizada: string;
  setOcorrenciaPersonalizada: Dispatch<SetStateAction<string>>;
  handleSendOrderEvent: () => Promise<void>;
}

interface IOcorrencia {
  id: number;
  descricao: string;
  finalizaPedido: boolean;
}

export const useRouteOrderListModal = (): IUseRouteOrderListModal => {
  const dispatch = useAsyncDispatch();
  const user = useSelector(selectUsuario);

  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const { refreshRoutes } = useContext(Context);
  const navigate = useNavigate();
  const cancelTokenRef = useRef<CancelTokenSource>();
  const cancelToken = axios.CancelToken;
  const [route, setRoute] = useState<IRota | null>(null);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [tipoOcorrencia, setTipoOcorrencia] = useState<IOcorrencia>();
  const [ocorrenciaPersonalizada, setOcorrenciaPersonalizada] =
    useState<string>('');
  const [tiposOcorrencia, setTiposOcorrencia] = useState<IOcorrencia[]>([
    {
      id: 0,
      descricao: 'Entregue',
      finalizaPedido: true,
    },
    {
      id: 1,
      descricao: 'Não Entregue',
      finalizaPedido: false,
    },
    {
      id: -1,
      descricao: 'Personalizado',
      finalizaPedido: false,
    },
  ]);

  const handleOpenModal = (selectedRoute: IRota): void => {
    setRoute(selectedRoute);

    onOpen();
  };

  const handleCloseModal = (): void => {
    onClose();
    refreshRoutes();
    setRoute(null);
  };

  const handleConfirmDelivery = async (): Promise<void> => {
    if (!route) return;

    setLoadingAction(true);
    const source: CancelTokenSource = cancelToken.source();
    cancelTokenRef.current = source;

    await dispatch(
      postRouteFinalize({
        body: {
          idRota: route.idRota,
          idDeposito: user?.defaultWarehouse.idDeposito || 0,
          nomeUsuario: user?.name || '',
        },
        cancelToken: source,
      })
    )
      .unwrap()
      .then(() => handleCloseModal())
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao confirmar entrega, por favor tente mais tarde');
      })
      .finally(() => setLoadingAction(false));
  };
  const handleExportXLSX = (rota: IRota): void => {
    converterEbaixarXLSX(
      rota,
      `${rota.descricao}-${formatarData(new Date(), false, 'input')}.xlsx`
    );
  };

  const onSelectionChange = async (keys: number[] | 'all'): Promise<void> => {
    if (!route || !route.pedidos) return;
    if (keys !== 'all') setSelectedRows([...keys]);
    else {
      const orders = route.pedidos.map((item) => item.idRotaPedido);
      setSelectedRows(orders);
    }
  };

  const handleSendOrderEvent = async (): Promise<void> => {};

  return {
    isOpen,
    onOpenChange,
    theme,
    route,
    handleOpenModal,
    handleCloseModal,
    handleConfirmDelivery,
    loadingAction,
    handleExportXLSX,
    onSelectionChange,
    selectedRows,
    setTipoOcorrencia,
    tipoOcorrencia,
    tiposOcorrencia,
    ocorrenciaPersonalizada,
    setOcorrenciaPersonalizada,
    handleSendOrderEvent,
  };
};
