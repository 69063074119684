import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Deposito } from '../../../interfaces/IUsuario';
import { IMotorista } from '../../../interfaces/response/Motorista/IMotorista';
import { IVeiculoMotorista } from '../../../interfaces/response/VeiculoMotorista/IVeiculoMotorista';
import { ObterPedidos } from '../../../redux/features/clientsData/clientsDataThunk';
import {
  selectOrdersDate,
  selectRouteDetails,
  selectUsuario,
} from '../../../redux/features/generalData/generalDataSelectors';
import { setRouteDetails } from '../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../redux/store';
import getRandomHexColor from '../../../util/colorGenerator';
import { formatarData } from '../../../util/format';
import { ConfirmationModalHandle } from '../components/CreateRouteAccordion/components/ConfirmationModal/ConfirmationModal';
import { IPedido } from '../interfaces/IPedido';
import { IPedidoResponse } from '../interfaces/IPedidoResponse';
import { IRotaDetalhe } from '../interfaces/Rota/IRotaDetalhe';

export interface RouteError {
  errorCode: 'driverMaxOrders' | 'vehicleWeight' | 'vehicleValue';
  message: string;
}

export interface UseCreateRoutes {
  selectedOrders: IPedido[];
  warehouse: Deposito | undefined;
  vehicle: IVeiculoMotorista | undefined;
  driver: IMotorista | undefined;
  returnWarehouse: boolean;
  orders: IPedido[];
  toggleOrderSelection: (order: IPedido) => void;
  toggleOrderSelectionManyAdd: (orders: IPedido[]) => void;
  toggleOrderSelectionManyDelete: (orders: IPedido[]) => void;
  setDriver: Dispatch<SetStateAction<IMotorista | undefined>>;
  setVehicle: Dispatch<SetStateAction<IVeiculoMotorista | undefined>>;
  setReturnWarehouse: Dispatch<SetStateAction<boolean>>;
  getOrders: () => Promise<void>;
  loadingOrders: boolean;
  routeErrors: Set<RouteError>;
  setRouteErrors: Dispatch<SetStateAction<Set<RouteError>>>;
  confirmModalRef: React.RefObject<ConfirmationModalHandle>;
  isLoadingRouteDetails?: boolean;
  routeDetails: IRotaDetalhe | undefined;
  strokeColor: string;
}

const useCreateRoutes = (): UseCreateRoutes => {
  const dispatch = useAsyncDispatch();
  const [loadingOrders, setLoadingOrders] = useState(true);
  const { startOrdersDate, endOrdersDate, searchType } =
    useSelector(selectOrdersDate);
  const user = useSelector(selectUsuario);
  const confirmModalRef = useRef<ConfirmationModalHandle>(null);
  const [orders, setOrders] = useState<IPedido[]>([]);
  const [warehouse, setWarehouse] = useState<Deposito>();
  const [driver, setDriver] = useState<IMotorista | undefined>(undefined);
  const [returnWarehouse, setReturnWarehouse] = useState<boolean>(true);
  const [vehicle, setVehicle] = useState<IVeiculoMotorista | undefined>(
    undefined
  );
  const [selectedOrders, setSelectedOrders] = useState<IPedido[]>([]);
  const [routeErrors, setRouteErrors] = useState<Set<RouteError>>(new Set());
  const [strokeColor, setStrokeColor] = useState<string>('#2d81a3');
  const { routeDetails, isLoadingRouteDetails } =
    useSelector(selectRouteDetails);

  const getOrders = async (): Promise<void> => {
    setLoadingOrders(true);
    await dispatch(
      ObterPedidos({
        dataInicio: formatarData(startOrdersDate, true, 'input') ?? '',
        dataFim: formatarData(endOrdersDate, true, 'input') ?? '',
        searchType,
        idDeposito: user?.defaultWarehouse.idDeposito.toString() || '',
        onlyOk: true,
      })
    )
      .unwrap()
      .then((response: IPedidoResponse) => {
        setSelectedOrders([]);
        setOrders(response.pedidos || []);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao carregar pedidos, por favor tente mais tarde');
      })
      .finally(() => setLoadingOrders(false));
  };

  useEffect(() => {
    setWarehouse(user?.defaultWarehouse);
    getOrders();
    setStrokeColor(getRandomHexColor());
  }, []);

  const setRouteDetailsUndefinedHandler = async (): Promise<void> => {
    dispatch(
      setRouteDetails({
        routeDetails: undefined,
      })
    );
  };

  // Erase details on no orders selected
  useEffect(() => {
    if (selectedOrders.length === 0) setRouteDetailsUndefinedHandler();
  }, [selectedOrders]);

  const toggleOrderSelection = (order: IPedido): void => {
    if (!selectedOrders.includes(order)) {
      setSelectedOrders((prev) => [...prev, order]);
    } else {
      setSelectedOrders((prev) => [
        ...prev.filter((orderAux) => orderAux.idPedido !== order.idPedido),
      ]);
    }
  };

  const toggleOrderSelectionManyAdd = (pedidos: IPedido[]): void => {
    let selectedAux = selectedOrders;

    pedidos.forEach((order) => {
      const exists = selectedAux.some(
        (selectedOrder) => selectedOrder.idPedido === order.idPedido
      );
      if (!exists) {
        selectedAux = [...selectedAux, order];
      }
    });

    setSelectedOrders(selectedAux);
  };

  const toggleOrderSelectionManyDelete = (pedidos: IPedido[]): void => {
    let selectedAux = selectedOrders;

    pedidos.forEach((order) => {
      selectedAux = [
        ...selectedAux.filter(
          (orderAux) => orderAux.idPedido !== order.idPedido
        ),
      ];
    });

    setSelectedOrders(selectedAux);
  };

  return {
    driver,
    orders,
    selectedOrders,
    toggleOrderSelection,
    toggleOrderSelectionManyAdd,
    toggleOrderSelectionManyDelete,
    vehicle,
    setDriver,
    setVehicle,
    warehouse,
    getOrders,
    loadingOrders,
    routeErrors,
    setRouteErrors,
    confirmModalRef,
    routeDetails,
    isLoadingRouteDetails,
    strokeColor,
    returnWarehouse,
    setReturnWarehouse,
  };
};

export default useCreateRoutes;
