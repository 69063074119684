import { IPedido } from '../../../GestaoPedidos/Pedidos/interfaces/IPedido';
import { IRotaPedidoOcorrencia } from './IRotaPedidoOcorrencia';

export interface IRotaPedido {
  idRotaPedido: number;
  idRota: number;
  idPedido: number;
  metadados: string;
  volumesConferidos: number;
  ordem: number;
  ocorrencias: IRotaPedidoOcorrencia[];
}

export const GetRotaPedidoObj = (pedido: IRotaPedido): IPedido => {
  try {
    const metadataObj = JSON.parse(pedido?.metadados);
    const transformed = lowercaseFirstLetter(metadataObj);

    // Certifique-se de que 'nf' existe após a transformação
    if (!('nf' in transformed) && 'NF' in metadataObj) {
      transformed.nf = metadataObj.NF;
    }

    return transformed as IPedido;
  } catch (error) {
    console.error('Erro ao processar metadados:', error);
    return {} as IPedido; // Retorna um objeto vazio para evitar falhas na aplicação
  }
};

export const lowercaseFirstLetter = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(lowercaseFirstLetter);
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key.charAt(0).toLowerCase() + key.slice(1), // Converte só a primeira letra
        lowercaseFirstLetter(value), // Aplica recursivamente em objetos aninhados
      ])
    );
  }
  return obj;
};
