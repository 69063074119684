import { SortDescriptor } from '@nextui-org/react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { formatarData } from '../../../../../../util/format';
import {
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../../util/mask';
import { GetDescription } from '../../../enum/ESituacaoPedido';
import { IPedido } from '../../../interfaces/IPedido';

type Column = {
  key: any;
  name: string;
};

export interface UseOrderListTableProps {
  orders: IPedido[];
}

export interface UseOrderListTable {
  cellData: (order: IPedido | undefined, columnKey: any) => number | string;
  columns: Column[];
  setSortDescriptor: Dispatch<SetStateAction<SortDescriptor>>;
  sortDescriptor: SortDescriptor;
  sortedOrders: IPedido[] | undefined;
}

const useOrderListTable = ({
  orders,
}: UseOrderListTableProps): UseOrderListTable => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'Ordem de entrega',
    direction: 'descending',
  });

  const columns: Column[] = [
    {
      key: 'ID',
      name: 'ID',
    },
    {
      key: 'SERVIÇO',
      name: 'SERVIÇO',
    },
    {
      key: 'NR NF',
      name: 'NR NF',
    },
    {
      key: 'SERIE NF',
      name: 'SERIE NF',
    },
    {
      key: 'CHAVE NF',
      name: 'CHAVE NF',
    },
    {
      key: 'NR CTE',
      name: 'NR CTE',
    },
    {
      key: 'CHAVE CTE',
      name: 'CHAVE CTE',
    },
    {
      key: 'VL FRETE',
      name: 'VL FRETE',
    },
    {
      key: 'VL CUBADO',
      name: 'VL CUBADO',
    },
    {
      key: 'PESO CUBADO',
      name: 'PESO CUBADO',
    },
    {
      key: 'PESO TOTAL',
      name: 'PESO TOTAL',
    },
    {
      key: 'QT VOLUMES',
      name: 'QT VOLUMES',
    },
    {
      key: 'TOTAL NF',
      name: 'TOTAL NF',
    },
    {
      key: 'PREVISÃO DE ENTREGA',
      name: 'PREVISÃO DE ENTREGA',
    },
    {
      key: 'CLIENTE',
      name: 'CLIENTE',
    },
    {
      key: 'CIDADE',
      name: 'CIDADE',
    },
    {
      key: 'UF',
      name: 'UF',
    },
    {
      key: 'CEP',
      name: 'CEP',
    },
    {
      key: 'SITUAÇÃO',
      name: 'SITUAÇÃO',
    },
  ];

  const cellData = (order: IPedido | undefined, columnKey: any): any => {
    switch (columnKey) {
      case 'ID':
        return order?.idPedido;
      case 'SERVIÇO':
        return order?.servico;
      case 'NR NF':
        return order?.nf;
      case 'SERIE NF':
        return order?.serieNf;
      case 'CHAVE NF':
        return order?.chaveAcessoNF;
      case 'NR CTE':
        return order?.cte || '';
      case 'CHAVE CTE':
        return order?.chaveAcessoCte || '';
      case 'VL FRETE':
        return formatarMoeda(order?.valorFrete || 0);
      case 'VL CUBADO':
        return formatarMoeda(order?.valorCubagemtotal || 0);
      case 'PESO CUBADO':
        return formatarMedidaGenerico(order?.valorPesoCubado || 0, 'KG');
      case 'PESO TOTAL':
        return formatarMedidaGenerico(order?.valorPesoTotal || 0, 'KG');
      case 'QT VOLUMES':
        return order?.quantidadeVolumes;
      case 'TOTAL NF':
        return formatarMoeda(order?.valorTotalNf || 0);
      case 'PREVISÃO DE ENTREGA':
        return formatarData(order?.dataPrevisaoEntrega, true, 'normal') || '';
      case 'CLIENTE':
        return order?.destinatarioNome;
      case 'CIDADE':
        return order?.destinatarioCidade;
      case 'UF':
        return order?.destinatarioUf;
      case 'CEP':
        return order?.destinatarioCep;
      case 'SITUAÇÃO':
        return GetDescription(order?.situacaoPedido);
      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return (
      orders &&
      [...orders].sort((a: IPedido, b: IPedido) => {
        const first = cellData(a, sortDescriptor.column as any) as number;
        const second = cellData(b, sortDescriptor.column as any) as number;

        let cmp = 0;
        if (first < second) cmp = -1;
        if (first > second) cmp = 1;

        return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
      })
    );
  }, [sortDescriptor, orders]);

  return {
    cellData,
    columns,
    setSortDescriptor,
    sortDescriptor,
    sortedOrders,
  };
};

export default useOrderListTable;
