/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
import { Tooltip } from '@nextui-org/react';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { Deposito } from '../../../../interfaces/IUsuario';
import Marker from '../../../../pages/CreateRoute/components/Marker/Marker';
import Polyline from '../../../../pages/CreateRoute/components/Polyline/Polyline';
import WarehouseTooltipCard from '../../../../pages/CreateRoute/components/WarehouseTooltipCard/WarehouseTooltipCard';
import { IRota } from '../../../../pages/CreateRoute/interfaces/Rota/IRota';
import { GetRotaPedidoObj } from '../../../../pages/CreateRoute/interfaces/Rota/IRotaPedido';
import { moneyMask } from '../../../../util/mask';

const TootipCard: FC<{
  volumes: number;
  vlCarga: number;
  pesoTaxado: number;
}> = ({ volumes, vlCarga, pesoTaxado }): JSX.Element => {
  return (
    <div className="p-0.5">
      {/* Volumes */}
      <p className="text-xs ">
        <span className="font-semibold">Volumes: </span>
        {volumes}
      </p>

      {/* Value */}
      <p className="text-xs ">
        <span className="font-semibold">Valor: </span>
        {moneyMask(vlCarga, 'R$', 2)}
      </p>

      {/* Weight */}
      <p className="text-xs ">
        <span className="font-semibold">Peso taxado: </span>
        {moneyMask(pesoTaxado, '', 2)} kg
      </p>
    </div>
  );
};

export const OrderLocations: FC<{
  map: google.maps.Map;
  warehouse: Deposito | undefined;
  rotas: IRota[];

  showWaypoints?: boolean;
}> = ({ map, warehouse, rotas, showWaypoints }): JSX.Element => {
  return (
    <>
      {/* Warehouse Marker */}
      <Marker
        key="warehouse"
        map={map}
        position={{
          lat: parseFloat(warehouse?.endereco.lat || ''),
          lng: parseFloat(warehouse?.endereco.lng || ''),
        }}
      >
        <Tooltip
          content={<WarehouseTooltipCard warehouse={warehouse} />}
          showArrow
        >
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: Math.random(),
              duration: 0.2,
              ease: 'easeInOut',
            }}
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/color/48/garage-closed.png"
                alt="garage-closed"
              />
            </motion.button>
          </motion.div>
        </Tooltip>
      </Marker>
      {/* Order Markers */}
      {rotas.map((rota) => (
        <div>
          {rota.pedidos &&
            rota.pedidos.map((pedidoAux) => {
              const pedido = GetRotaPedidoObj(pedidoAux);
              return (
                <Marker
                  key={`${pedidoAux.idRotaPedido}`}
                  map={map}
                  position={{
                    lat: pedido.destinatarioLatitude || 0,
                    lng: pedido.destinatarioLongitude || 0,
                  }}
                >
                  <Tooltip
                    content={
                      <TootipCard
                        pesoTaxado={pedido.valorPesoTotal || 0}
                        vlCarga={pedido.valorTotalNf}
                        volumes={pedido.quantidadeVolumes}
                      />
                    }
                    showArrow
                  >
                    <motion.div
                      initial={{ y: -100, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        delay: 0.1 + Math.random() * (1.0 - 0.1),
                        duration: 0.2,
                        ease: 'easeInOut',
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            backgroundColor: `${
                              rota.polylines?.at(0)?.color || '#000'
                            }`,
                            border: `1px solid #3c3c3c`,
                            borderRadius: '50%',
                          }}
                        />
                      </motion.button>
                    </motion.div>
                  </Tooltip>
                </Marker>
              );
            })}
          {rota.polylines &&
            rota.polylines.map((polilyne, index) => (
              <Polyline
                key={`${polilyne.idRota}-${index.toString()}`}
                path={polilyne.polyline}
                map={map}
                strokeColor={polilyne.color || '#0a6d63'}
              />
            ))}
        </div>
      ))}
    </>
  );
};
