import { Spinner } from '@nextui-org/react';
import React from 'react';
import BlankState from '../../../components/BlankState/BlankState';
import RoutesTable from '../../../components/RoutesTable/RoutesTable';
import { Context } from '../../../layouts/GerirRotasLayout/GerirRotasLayout';
import useASair from './components/hooks/useASair';
import RouteOrderListModal from './components/RouteOrderListModal/RouteOrderListModal';

const ASair = (): JSX.Element => {
  const { routes, loading } = React.useContext(Context);
  const { modalRef } = useASair();

  if (loading) return <Spinner label="Baixando conteúdo" color="default" />;
  return (
    <div>
      {/* Aprove route modal */}
      <RouteOrderListModal ref={modalRef} />

      {/* Table */}
      {routes.length !== 0 && (
        <RoutesTable
          routes={routes}
          onRowClick={(route) => {
            if (modalRef.current) modalRef.current.openModal(route);
          }}
        />
      )}

      {/* Blank State */}
      {routes.length === 0 && <BlankState label="Sem rotas no momento" />}
    </div>
  );
};

export default ASair;
