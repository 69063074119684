/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Image } from '@nextui-org/react';
import React, { forwardRef, useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import { IMotorista } from '../../../interfaces/response/Motorista/IMotorista';
import { IVeiculo } from '../../../interfaces/response/Veiculo/IVeiculo';
import {
  ObterMotorista,
  ObterVinculoVeiculoMotorista,
} from '../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../redux/features/generalData/generalDataSelectors';
import { useAsyncDispatch } from '../../../redux/store';
import { formatarData } from '../../../util/format';
import { cepMask, formatarMedidaGenerico } from '../../../util/mask';
import { IRota } from '../../CreateRoute/interfaces/Rota/IRota';
import {
  GetRotaPedidoObj,
  IRotaPedido,
} from '../../CreateRoute/interfaces/Rota/IRotaPedido';

const ImpressaoRota = forwardRef<HTMLDivElement, IRota>((route, ref) => {
  const user = useSelector(selectUsuario);
  const dispatch = useAsyncDispatch();

  const [veiculo, setVeiculo] = useState<IVeiculo>();
  const [motorista, setMotorista] = useState<IMotorista>();

  useEffect(() => {
    getVeiculoMotorista(route.idVeiculoMotorista);
  }, []);

  const getVeiculoMotorista = async (
    idVeiculoMotorista: number
  ): Promise<void> => {
    if (!idVeiculoMotorista) return;
    await dispatch(
      ObterVinculoVeiculoMotorista({
        idVeiculoMotorista,
        idDeposito: user?.defaultWarehouse.idDeposito || 0,
      })
    )
      .unwrap()
      .then((res) => {
        setVeiculo(res.veiculoMotorista.veiculo);
        getMotorista(res.veiculoMotorista.idMotorista);
      });
  };

  const getMotorista = async (idMotorista: number): Promise<void> => {
    if (!idMotorista) return;

    await dispatch(
      ObterMotorista({
        idMotorista,
      })
    )
      .unwrap()
      .then((res) => {
        setMotorista(res.motorista);
      });
  };

  const handlegGetRouteVolumes = (pedidos: IRotaPedido[]): string => {
    let quantidadeVolume = 0;

    pedidos.forEach((item) => {
      const pedido = GetRotaPedidoObj(item);
      quantidadeVolume += pedido.quantidadeVolumes;
    });
    return quantidadeVolume.toString();
  };

  const handlegGetRouteWeight = (pedidos: IRotaPedido[]): number => {
    let valorPeso = 0;

    pedidos.forEach((item) => {
      const pedido = GetRotaPedidoObj(item);
      valorPeso += pedido.valorPesoTotal || 0;
    });
    return valorPeso;
  };
  const handlegGetRouteCubage = (pedidos: IRotaPedido[]): number => {
    let valorCubagem = 0;

    pedidos.forEach((item) => {
      const pedido = GetRotaPedidoObj(item);
      valorCubagem += pedido.valorCubagemtotal || 0;
    });
    return valorCubagem;
  };

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: 2480,
        gap: 8,
        padding: 8,
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: 8,
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '20%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            backgroundColor: '#6f6f6f',
            borderRadius: 8,
          }}
        >
          <Image
            style={{
              width: '80%',
              margin: '0 auto',
            }}
            className="rounded-none"
            alt="Company Logo"
            src={user?.customerAccess.customer.logo}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '80%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 12,
          }}
        >
          <span
            style={{
              fontSize: '28px',
              fontWeight: 500,
            }}
          >
            FOLHA DE EMBARQUE
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: 12,
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '40%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <LabelInput
              label="FILIAL"
              value={user?.defaultWarehouse.descricao.toUpperCase() || ''}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '30%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput label="NOME DA ROTA" value={route.descricao} />
        </div>
        <div
          style={{
            display: 'flex',
            width: '30%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput
            label="DATA DE SAIDA"
            value={formatarData(route.dataSaida, true, 'normal') || ''}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: 12,
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput
            label="MOTORISTA"
            value={motorista?.nome.toUpperCase() || ''}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput
            label="VEICULO"
            value={veiculo?.placa.toUpperCase() || ''}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: 12,
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput
            label="TOTAL DE ENTREGAS"
            value={route.pedidos?.length.toString() || '0'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput
            label="TOTAL DE VOLUMES"
            value={handlegGetRouteVolumes(route.pedidos || [])}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput
            label="PESO"
            value={formatarMedidaGenerico(
              handlegGetRouteWeight(route.pedidos || []),
              'KG'
            )}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #b3b3b3',
            borderRadius: 8,
            padding: 4,
          }}
        >
          <LabelInput
            label="M3"
            value={formatarMedidaGenerico(
              handlegGetRouteCubage(route.pedidos || []),
              'M3',
              5
            )}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: 12,
          flexDirection: 'row',
        }}
      >
        <OrdersTable rotaPedidos={route.pedidos || []} />
      </div>
    </div>
  );
});

const LabelInput = ({
  label,
  value,
}: {
  label: string;
  value: string;
}): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <span style={{ fontSize: 12, fontWeight: 600 }}>{label}</span>
      <span style={{ padding: 4, fontWeight: 400, color: '#595959' }}>
        {value.toUpperCase()}
      </span>
    </div>
  );
};

const OrdersTable = ({
  rotaPedidos,
}: {
  rotaPedidos: IRotaPedido[];
}): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 8,
        border: '1px solid #cccccc',
      }}
    >
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          borderRadius: 8,
          overflow: 'hidden',
        }}
      >
        <thead
          style={{ fontSize: 12, fontWeight: 400, backgroundColor: '#f8f8f8' }}
        >
          <td
            style={{
              padding: 10,
              fontSize: 10,
              textAlign: 'center',
              width: '10%',
            }}
          >
            ORDEM
          </td>
          <td
            style={{
              padding: 10,
              fontSize: 10,
              textAlign: 'center',
              width: '15%',
            }}
          >
            NOTA FISCAL
          </td>
          <td
            style={{
              padding: 10,
              fontSize: 10,
              textAlign: 'center',
              width: '10%',
            }}
          >
            CEP
          </td>
          <td
            style={{
              padding: 10,
              fontSize: 10,
              textAlign: 'center',
              width: '10%',
            }}
          >
            VOLUME
          </td>
          <td
            style={{
              padding: 10,
              fontSize: 10,
              textAlign: 'center',
              width: '10%',
            }}
          >
            PESO
          </td>
          <td
            style={{
              padding: 10,
              fontSize: 10,
              textAlign: 'center',
              width: '10%',
            }}
          >
            M3
          </td>
          <td
            style={{
              padding: 10,
              fontSize: 10,
              textAlign: 'center',
              width: '35%',
            }}
          >
            CODIGO DE BARRAS
          </td>
        </thead>
        <tbody>
          {rotaPedidos.map((item, index) => {
            const pedido = GetRotaPedidoObj(item);

            return (
              <tr
                key={index}
                style={{
                  width: '100%',
                  fontSize: 12,
                  fontWeight: 400,
                  borderTop: '1px solid #cccccc',
                }}
              >
                <td
                  style={{
                    padding: 4,
                    fontSize: 10,
                    textAlign: 'center',
                    width: '10%',
                  }}
                >
                  {item.ordem}
                </td>
                <td
                  style={{
                    padding: 4,
                    fontSize: 10,
                    textAlign: 'center',
                    width: '15%',
                  }}
                >
                  {pedido.nf}
                </td>
                <td
                  style={{
                    padding: 4,
                    fontSize: 10,
                    textAlign: 'center',
                    width: '10%',
                  }}
                >
                  {cepMask(pedido.destinatarioCep)}
                </td>
                <td
                  style={{
                    padding: 4,
                    fontSize: 10,
                    textAlign: 'center',
                    width: '10%',
                  }}
                >
                  {pedido.quantidadeVolumes}
                </td>
                <td
                  style={{
                    padding: 4,
                    fontSize: 10,
                    textAlign: 'center',
                    width: '10%',
                  }}
                >
                  {pedido.valorPesoTotal}
                </td>
                <td
                  style={{
                    padding: 4,
                    fontSize: 10,
                    textAlign: 'center',
                    width: '10%',
                  }}
                >
                  {pedido.valorCubagemtotal}
                </td>
                <td style={{ padding: 4, textAlign: 'center', width: '35%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <Barcode
                      value={pedido.chaveAcessoNF}
                      width={1.1}
                      height={22}
                      fontSize={10}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ImpressaoRota;
