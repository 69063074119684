import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ApplicationLayout from './layouts/ApplicationLayout/ApplicationLayout';
import AuthLayout from './layouts/AuthLayout/AuthLayout';
import CadastrosLayout from './layouts/CadastrosLayout/CadastrosLayout';
import CheckOrdersLayout from './layouts/CheckOrdersLayout/CheckOrdersLayout';
import DetalheMotoristaLayout from './layouts/DetalheMotoristaLayout/DetalheMotoristaLayout';
import DetalheRotaLayout from './layouts/DetalheRotaLayout/DetalheRotaLayout';
import GerirRotas from './layouts/GerirRotasLayout/GerirRotasLayout';
import LoginLayout from './layouts/LoginLayout/LoginLayout';
import PedidosLayout from './layouts/PedidosLayout/PedidosLayout';
import Account from './pages/Account/Account';
import Motorista from './pages/Cadastros/Motorista/Motorista';
import TabelaPreco from './pages/Cadastros/TabelaPreco/TabelaPreco';
import TipoVeiculo from './pages/Cadastros/TipoVeiculo/TipoVeiculo';
import Veiculo from './pages/Cadastros/Veiculos/Veiculos';
import CheckOrders from './pages/CheckOrders/CheckOrders';
import CreateRoutes from './pages/CreateRoute/CreateRoutes';
import DetalheMotorista from './pages/DetalheMotorista/DetalheMotorista';
import AConferir from './pages/GerirRotas/AConferir/AConferir';
import AguardandoAprovacao from './pages/GerirRotas/AguardandoAprovacao/AguardandoAprovacao';
import ASair from './pages/GerirRotas/ASair/ASair';
import Canceladas from './pages/GerirRotas/Canceladas/Canceladas';
import DetalheRota from './pages/GerirRotas/DetalheRota/DetalheRota';
import Finalizadas from './pages/GerirRotas/Finalizadas/Finalizadas';
import Pedidos from './pages/GestaoPedidos/Pedidos/Pedidos';
import { selectAuthAttributs } from './redux/features/generalData/generalDataSelectors';

const App: React.FunctionComponent = (): JSX.Element => {
  const auth = useSelector(selectAuthAttributs);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          {auth === 'LoggedOut' && (
            <Route path="/" element={<LoginLayout />}>
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          )}
          {auth === 'LoggedIn' && (
            <Route path="/" element={<ApplicationLayout />}>
              <Route index element={<Navigate to="gestao-de-pedidos" />} />

              {/* My Account */}
              <Route path="minha-conta" element={<Account />} />

              {/* Gerir Rotas */}
              <Route path="rotas" element={<GerirRotas />}>
                <Route index element={<Navigate to="aguardando-aprovacao" />} />
                <Route
                  path="aguardando-aprovacao"
                  element={<AguardandoAprovacao />}
                />
                <Route path="a-conferir" element={<AConferir />} />
                <Route path="em-rota" element={<ASair />} />
                <Route path="finalizadas" element={<Finalizadas />} />
                <Route path="canceladas" element={<Canceladas />} />
              </Route>

              {/* Detalhe Rota */}
              <Route path="rota/detalhe/:id" element={<DetalheRotaLayout />}>
                <Route index element={<DetalheRota />} />
              </Route>

              {/* Gestão de pedidos */}
              <Route path="gestao-de-pedidos" element={<PedidosLayout />}>
                <Route index element={<Pedidos />} />
                <Route path="pedidos" element={<Pedidos />} />
              </Route>

              {/* Cadastros */}
              <Route path="cadastros" element={<CadastrosLayout />}>
                <Route index element={<Motorista />} />
                <Route path="motorista" element={<Motorista />} />
                <Route path="tabela-preco" element={<TabelaPreco />} />
                <Route path="veiculo" element={<Veiculo />} />
                <Route path="tipo-veiculo" element={<TipoVeiculo />} />
              </Route>

              {/* Detalhe Motorista */}
              <Route
                path="cadastros/motorista/:id"
                element={<DetalheMotoristaLayout />}
              >
                <Route index element={<DetalheMotorista />} />
              </Route>

              {/* Conferência de pedidos */}
              <Route
                path="conferencia-de-pedidos"
                element={<CheckOrdersLayout />}
              >
                <Route index element={<CheckOrders />} />
              </Route>

              {/* Create route */}
              <Route path="criar-rota" element={<CreateRoutes />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
