import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, CancelTokenSource } from 'axios';
import { OrderAPI } from '../../../config/api';
import { IBadRequestResponse } from '../../../interfaces/IBadRequestResponse';

export const patchCheckOrder = createAsyncThunk(
  'rotas/patchCheckOrder',
  async (
    args: {
      body: { nfKey: string };
      cancelToken: CancelTokenSource;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const {
      cancelToken,
      body: { nfKey },
    } = args;

    try {
      const { data } = await OrderAPI.patch(
        `/api/v1/routes/orders/check/${nfKey}`,
        {
          cancelToken: cancelToken.token,
        }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);
