import { Image } from '@nextui-org/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import logo from '../../assets/roteiriz-logo.png';
import { ThemeContext } from '../../contexts/ThemeContext/ThemeContext';
import Login from '../../pages/Login/Login';

type Step = 'login' | 'newPassword';

const LoginLayout = (): JSX.Element => {
  const [layout, setLayout] = useState<Step>('login');

  const theme = useContext(ThemeContext);
  return (
    <div className="flex flex-row items-center justify-center overflow-hidden bg-background">
      {/* Title Card */}
      <div className="flex w-full min-w-[60%] grow flex-col items-center justify-center">
        {/* Title */}
        <h1 className="my-10 text-center font-sans text-4xl font-bold text-foreground-600">
          Roteirizador de entregas
        </h1>
        <div
          className={`flex w-full items-center justify-center ${
            theme.theme === 'light' ? 'bg-foreground-300' : 'bg-foreground-500'
          } py-12 shadow-md`}
        >
          <Image
            width={350}
            className="rounded-none"
            alt="Company Logo"
            src={logo}
          />
        </div>
      </div>

      {/* Login card */}
      <div className="relative flex w-full grow flex-col items-center justify-center rounded-l-xl bg-foreground-50 shadow-large md:h-screen">
        {/* Login */}
        <AnimatePresence>
          {layout === 'login' && (
            <motion.div
              className="absolute w-[60%]"
              key="1"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
            >
              <Login changeLayout={() => setLayout('newPassword')} />
            </motion.div>
          )}
        </AnimatePresence>

        {/* New Password */}
        <AnimatePresence>
          {layout === 'newPassword' && (
            <motion.div
              className="absolute w-[60%]"
              key="2"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
            >
              {/* <NewPassword chengeLayout={() => setLayout('login')} /> */}
              <p>Create new password page</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default LoginLayout;
