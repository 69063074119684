import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
} from '@nextui-org/react';
import React from 'react';
import { IPedido } from '../../interfaces/IPedido';
import { IVolume } from '../../interfaces/IVolume';
import DetalhePedido from './components/Pedido/DetalhePedido';
import TabDetalheDestinatario from './components/Tabs/TabDetalheDestinatario';
import TabDetalheRemetente from './components/Tabs/TabDetalheRemetente';
import TabDetalheVolumes from './components/Tabs/TabDetalheVolumes';
import useModalDetalhePedido from './hooks/useModalDetalhePedido';

interface ModalDetalhePedidoProps {
  pedido: IPedido | undefined;
  volumes: IVolume[] | undefined;
  isOpen: boolean;
  handleClose: () => void;
}

const ModalDetalhePedido = ({
  pedido,
  volumes,
  isOpen,
  handleClose,
}: ModalDetalhePedidoProps): JSX.Element => {
  const { theme } = useModalDetalhePedido();

  const handleKeyPress = (target: any): void => {
    if (target.charCode === 27) {
      handleClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className={theme}
      onClose={handleClose}
      size="5xl"
      onKeyPress={handleKeyPress}
    >
      {pedido && (
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-default-500">
                Pedido: {pedido?.idPedido} <Divider />
              </ModalHeader>
              <ModalBody className="text-sm text-default-500">
                <DetalhePedido pedido={pedido} />
                <Tabs aria-label="Options">
                  <Tab key="destinatario" title="Destinatário">
                    <TabDetalheDestinatario pedido={pedido} />
                  </Tab>
                  <Tab key="remetente" title="Remetente">
                    <TabDetalheRemetente pedido={pedido} />
                  </Tab>
                  <Tab key="volumes" title="Volumes">
                    <TabDetalheVolumes volumes={volumes} />
                  </Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {/* <Button color="primary" onPress={onClose}>
                Action
              </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      )}
    </Modal>
  );
};

export default ModalDetalhePedido;
