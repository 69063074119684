import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useContext,
  useImperativeHandle,
} from 'react';

import { Context } from '../../../../../layouts/GerirRotasLayout/GerirRotasLayout';
import { IRota } from '../../../../CreateRoute/interfaces/Rota/IRota';
import { GetRotaPedidoObj } from '../../../../CreateRoute/interfaces/Rota/IRotaPedido';
import PrintButton from '../../../ASair/components/RouteOrderListModal/components/PrintButton/PrintButton';
import OrderListTable from '../OrderListTable/OrderListTable';
import { useRouteOrderListModal } from './hooks/useRouteOrderListModal';

export interface IRouteOrderListModalHandle {
  openModal: (selectedRoute: IRota) => void;
  closeModal: () => void;
}

const RouteOrderListModal: ForwardRefRenderFunction<
  IRouteOrderListModalHandle
> = (_, ref): JSX.Element => {
  useContext(Context);
  const {
    isOpen,
    onOpenChange,
    theme,
    route,
    handleOpenModal,
    handleCloseModal,
    handleApproveRoute,
    loadingApproval,
  } = useRouteOrderListModal();

  useImperativeHandle(ref, () => ({
    openModal: (selectedRoute: IRota) => {
      handleOpenModal(selectedRoute);
    },
    closeModal: () => {
      handleCloseModal();
    },
  }));

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={`${theme} max-h-[80%] max-w-[90%]`}
      onClose={handleCloseModal}
    >
      <ModalContent>
        {/* Header */}
        <ModalHeader className="text-default-600">Liberar rota</ModalHeader>

        {/* Body */}
        <ModalBody>
          {/* Loading Spinner */}
          {route === null && <Spinner />}

          {/* Information text */}
          {route && (
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm text-default-500">
                Liberar a rota{' '}
                <span className="font-bold">{route.descricao}</span> com os
                pedidos listados abaixo?
              </p>

              <PrintButton route={route} />
            </div>
          )}

          {/* Order Table */}
          {route && route.pedidos && <OrderListTable orders={route.pedidos} />}
        </ModalBody>

        {/* Footer */}
        <ModalFooter>
          <Button color="danger" onPress={handleCloseModal}>
            Voltar
          </Button>

          <Button
            type="submit"
            onPress={handleApproveRoute}
            isLoading={loadingApproval}
            isDisabled={route?.pedidos?.some(
              (pedido) =>
                pedido.volumesConferidos !==
                GetRotaPedidoObj(pedido).quantidadeVolumes
            )}
          >
            Liberar para saída
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(RouteOrderListModal);
