import { Input } from '@nextui-org/react';
import React from 'react';
import { formatarData } from '../../../../../../../util/format';
import {
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../../../util/mask';
import { IPedido } from '../../../../interfaces/IPedido';

interface ITabProps {
  pedido: IPedido;
}
const DetalhePedido = ({ pedido }: ITabProps): JSX.Element => {
  return (
    <div>
      <div className="flex w-full flex-col gap-2">
        {/* Linha 1: Peso Taxado, Peso Real, Peso Cubado, Data Emissao */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="w-1/5">
            <Input size="sm" label="SERVIÇO" value={pedido.servico} disabled />
          </div>
          <div className="w-1/5">
            <Input size="sm" label="NR NF" value={pedido.nf} disabled />
          </div>
          <div className="w-1/5">
            <Input size="sm" label="SERIE NF" value={pedido.serieNf} disabled />
          </div>
          <div className="w-2/5 flex-1">
            <Input
              size="sm"
              label="CHAVE NFE"
              value={pedido.chaveAcessoNF}
              disabled
            />
          </div>
        </div>
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input size="sm" label="CTE" value={pedido.cte} disabled />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="CHAVE CTE"
              value={pedido.chaveAcessoCte}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="TOTAL NF"
              value={formatarMoeda(pedido.valorTotalNf)}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="PREVISÃO DE ENTREGA"
              value={
                formatarData(pedido.dataPrevisaoEntrega, true, 'normal') || ''
              }
              disabled
            />
          </div>
        </div>
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="sm"
              label="VALOR FRETE"
              value={formatarMoeda(pedido.valorFrete)}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="CUBAGEM TOTAL"
              value={formatarMedidaGenerico(
                pedido.valorCubagemtotal || 0,
                'M3'
              )}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="PESO CUBADO"
              value={formatarMedidaGenerico(pedido.valorPesoCubado || 0, 'KG')}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="sm"
              label="PESO TOTAL"
              value={formatarMedidaGenerico(pedido.valorPesoTotal || 0, 'KG')}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalhePedido;
