import { Input } from '@nextui-org/react';
import React from 'react';
import { mascararDocumento } from '../../../../../../../util/mask';
import { IPedido } from '../../../../interfaces/IPedido';

interface ITabProps {
  pedido: IPedido | undefined;
}

const TabDetalheRemetente = ({ pedido }: ITabProps): JSX.Element => {
  return (
    <div className="flex w-full flex-row gap-2">
      <div className="flex w-full flex-col gap-2">
        {/* Linha 1: Nome e Email */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Nome"
              value={pedido?.remetenteRazaoSocial}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="md"
              label="CNPJ"
              value={mascararDocumento(2, pedido?.remetenteDocumento || '')}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabDetalheRemetente;
