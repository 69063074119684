import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Spinner,
} from '@nextui-org/react';
import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import { FaFileDownload } from 'react-icons/fa';
import { Context } from '../../../../../layouts/GerirRotasLayout/GerirRotasLayout';
import { IRota } from '../../../../CreateRoute/interfaces/Rota/IRota';
import OrderListTable from '../OrderListTable/OrderListTable';
import PrintButton from './components/PrintButton/PrintButton';
import { useRouteOrderListModal } from './hooks/useRouteOrderListModal';

export interface IRouteOrderListModalHandle {
  openModal: (selectedRoute: IRota) => void;
  closeModal: () => void;
}

const RouteOrderListModal: React.ForwardRefRenderFunction<
  IRouteOrderListModalHandle
> = (_, ref): JSX.Element => {
  const { refreshRoutes } = useContext(Context);
  const {
    isOpen,
    onOpenChange,
    theme,
    route,
    handleOpenModal,
    handleCloseModal,
    handleConfirmDelivery,
    loadingAction,
    onSelectionChange,
    selectedRows,
    tipoOcorrencia,
    setTipoOcorrencia,
    tiposOcorrencia,
    ocorrenciaPersonalizada,
    setOcorrenciaPersonalizada,
    handleSendOrderEvent,
  } = useRouteOrderListModal();

  useImperativeHandle(ref, () => ({
    openModal: (selectedRoute: IRota) => {
      handleOpenModal(selectedRoute);
    },
    closeModal: () => {
      handleCloseModal();
      refreshRoutes();
    },
  }));

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className={`${theme} max-h-[80%] max-w-[90%]`}
      onClose={handleCloseModal}
    >
      <ModalContent>
        {/* Header */}
        <ModalHeader className="text-default-600">Finalizar Rota</ModalHeader>

        {/* Body */}
        <ModalBody>
          {/* Loading Spinner */}
          {route === null && <Spinner />}
          {/* Information text */}
          {route && (
            <div className="flex flex-row items-center justify-between">
              <p className="whitespace-nowrap text-sm text-default-500">
                Finalizar rota{' '}
                <span className="font-bold">{route.descricao}</span> com os
                pedidos listados abaixo?
              </p>

              <PrintButton route={route} />
            </div>
          )}
          {/* OCORRENCIA */}
          {selectedRows.length > 0 && (
            <div className="flex flex-row gap-2 p-4">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                }}
              >
                <Select
                  label="Selecione a Ocorrência"
                  value={tipoOcorrencia?.id}
                  onChange={(e) => {
                    if (e.target.value !== '-1') {
                      setOcorrenciaPersonalizada('');
                    }
                    setTipoOcorrencia(
                      tiposOcorrencia.find(
                        (x) => x.id.toString() === e.target.value
                      )
                    );
                  }}
                  style={{
                    width: 300,
                  }}
                >
                  {tiposOcorrencia.map((ocorrencia) => (
                    <SelectItem
                      key={ocorrencia.id}
                      value={ocorrencia.id}
                      textValue={ocorrencia.descricao}
                    >
                      <p className="text-foreground-700">
                        {ocorrencia.descricao}
                      </p>
                    </SelectItem>
                  ))}
                </Select>

                {tipoOcorrencia?.id === -1 && (
                  <Input
                    label="Descrição Personalizada"
                    style={{
                      width: 500,
                    }}
                    type="text"
                    value={ocorrenciaPersonalizada}
                    onChange={(e) => setOcorrenciaPersonalizada(e.target.value)}
                  />
                )}
              </div>
              <div>
                <Button
                  style={{ height: '100%' }}
                  color="default"
                  onPress={() => handleSendOrderEvent()}
                  isDisabled={
                    !tipoOcorrencia ||
                    (tipoOcorrencia.id === -1 &&
                      ocorrenciaPersonalizada.trim().length === 0)
                  }
                  startContent={
                    <FaFileDownload className="h-5 w-5 text-foreground-700" />
                  }
                >
                  Adicionar Ocorrência
                </Button>
              </div>
            </div>
          )}
          {/* Order Table */}{' '}
          {route && route.pedidos && (
            <OrderListTable
              onSelectionChange={onSelectionChange}
              orders={route.pedidos}
            />
          )}
        </ModalBody>

        {/* Footer */}
        <ModalFooter>
          <Button color="danger" onPress={handleCloseModal}>
            Voltar
          </Button>

          <Button
            type="submit"
            onPress={handleConfirmDelivery}
            isLoading={loadingAction}
            isDisabled={
              route?.pedidos?.filter((x) => x.ocorrencias).length === 0
            }
          >
            Finalizar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(RouteOrderListModal);
