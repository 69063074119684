import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  AuthSchemaAPI,
  CustomerAPI,
  OrderAPI,
  RoteirizadorAPI,
} from '../../../config/api';
import { IBadRequestResponse } from '../../../interfaces/IBadRequestResponse';
import { IUserListResponse } from '../../../interfaces/IUserClienteResponse';
import { IPutTrocarDepositoRequest } from '../../../interfaces/request/Deposito/IPutTrocarDepositoRequest';
import { IDetalheRotaRequest } from '../../../interfaces/request/DetalheRota/IDetalheRotaRequest';
import { ICadastraMotoristaRequest } from '../../../interfaces/request/Motorista/ICadastraMotoristaRequest';
import { IEditaMotoristaRequest } from '../../../interfaces/request/Motorista/IEditaMotoristaRequest';
import { ICadastraTabelaPrecoRequest } from '../../../interfaces/request/TabelaPreco/ICadastraTabelaPrecoRequest';
import { IEditaTabelaPrecoRequest } from '../../../interfaces/request/TabelaPreco/IEditaTabelaPrecoRequest';
import { ICadastraTipoVeiculoRequest } from '../../../interfaces/request/TipoVeiculo/ICadastraTipoVeiculoRequest';
import { IEditaTipoVeiculoRequest } from '../../../interfaces/request/TipoVeiculo/IEditaTipoVeiculoRequest';
import { ICadastraVeiculoRequest } from '../../../interfaces/request/Veiculo/ICadastraVeiculoRequest';
import { IEditaVeiculoRequest } from '../../../interfaces/request/Veiculo/IEditaVeiculoRequest';
import { ICadastraVeiculoMotoristaRequest } from '../../../interfaces/request/VeiculoMotorista/ICadastraVeiculoMotoristaRequest';
import { ITrocaDepositoResponse } from '../../../interfaces/response/Deposito/ITrocaDepositoResponse';
import { IMotoristaResponse } from '../../../interfaces/response/Motorista/IMotoristaResponse';
import { IConsultaTabelaPrecoResponse } from '../../../interfaces/response/TabelaPreco/IConsultaTabelaPrecoResponse';
import { ITabelaPrecoResponse } from '../../../interfaces/response/TabelaPreco/ITabelaPrecoResponse';
import { ITipoVeiculoResponse } from '../../../interfaces/response/TipoVeiculo/ITipoVeiculoResponse';
import { IConsultaVeiculoResponse } from '../../../interfaces/response/Veiculo/IConsultaVeiculoResponse';
import { IVeiculoResponse } from '../../../interfaces/response/Veiculo/IVeiculoResponse';
import { IConsultaVeiculoMotoristaResponse } from '../../../interfaces/response/VeiculoMotorista/IConsultaVeiculoMotoristaResponse';
import { IVeiculoMotoristaResponse } from '../../../interfaces/response/VeiculoMotorista/IVeiculoMotoristaResponse';
import { IRota } from '../../../pages/CreateRoute/interfaces/Rota/IRota';
import { IPedido as IPedidoV2 } from '../../../pages/GestaoPedidos/Pedidos/interfaces/IPedido';
import { convertFileToBase64 } from '../../../util/convertFileToBase64';

export const atualizaSenhaUsuario = createAsyncThunk(
  'clientsData/atualizaSenhaUsuario',
  async (
    args: {
      userId: number;
      productId: number;
      currentPassword: string;
      newPassword: string;
      token: string;
    },
    { rejectWithValue }
  ): Promise<IUserListResponse | any> => {
    try {
      const response = await AuthSchemaAPI.post(`api/v1/user/senha`, {
        usuario: {
          _Id: args.userId,
        },
        produto: {
          _Id: args.productId,
        },
        NovaSenha: args.newPassword,
      });

      if (response.data?.error !== undefined) return response.data;
      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterMotoristas = createAsyncThunk(
  'clientsData/ObterMotoristas',
  async (
    _,
    { rejectWithValue }
  ): Promise<IConsultaVeiculoMotoristaResponse | any> => {
    try {
      const response = await RoteirizadorAPI.get(`api/v1/motorista`);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterMotorista = createAsyncThunk(
  'clientsData/ObterMotorista',
  async (
    args: {
      idMotorista: number;
    },
    { rejectWithValue }
  ): Promise<IConsultaVeiculoMotoristaResponse | any> => {
    const { idMotorista } = args;
    try {
      const response = await RoteirizadorAPI.get(
        `api/v1/motorista/${idMotorista}`
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const cadastrarMotorista = createAsyncThunk(
  'clientsData/CadastrarMotorista',
  async (
    args: {
      body: ICadastraMotoristaRequest;
    },
    { rejectWithValue }
  ): Promise<IMotoristaResponse | any> => {
    const { body } = args;
    try {
      const response = await RoteirizadorAPI.post(`api/v1/motorista`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const editarMotorista = createAsyncThunk(
  'clientsData/EditarMotorista',
  async (
    args: {
      body: IEditaMotoristaRequest;
    },
    { rejectWithValue }
  ): Promise<IMotoristaResponse | any> => {
    const { body } = args;
    try {
      const response = await RoteirizadorAPI.put(`api/v1/motorista`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterVeiculosMotorista = createAsyncThunk(
  'clientsData/ObterVeiculosMotorista',
  async (
    args: {
      idMotorista: number;
    },
    { rejectWithValue }
  ): Promise<IConsultaVeiculoMotoristaResponse | any> => {
    const { idMotorista } = args;
    try {
      const response = await RoteirizadorAPI.get(
        `api/v1/motorista/Veiculo/${idMotorista}`
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterVinculoVeiculoMotorista = createAsyncThunk(
  'clientsData/ObterVinculoVeiculoMotorista',
  async (
    args: {
      idVeiculoMotorista: number;
      idDeposito: number;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { idVeiculoMotorista, idDeposito } = args;
    try {
      const response = await RoteirizadorAPI.get(
        `api/v1/motorista/veiculo/vinculo/${idVeiculoMotorista}/${idDeposito}`
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const AtribuirVeiculoMotorista = createAsyncThunk(
  'clientsData/AtribuirVeiculoMotorista',
  async (
    args: {
      body: ICadastraVeiculoMotoristaRequest;
    },
    { rejectWithValue }
  ): Promise<IVeiculoMotoristaResponse | any> => {
    const { body } = args;
    try {
      const response = await RoteirizadorAPI.post(
        `api/v1/motorista/Veiculo`,
        body
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const AlteraVeiculoPadrao = createAsyncThunk(
  'clientsData/AlteraVeiculoPadrao',
  async (
    args: {
      body: ICadastraVeiculoMotoristaRequest;
    },
    { rejectWithValue }
  ): Promise<IVeiculoMotoristaResponse | any> => {
    const { body } = args;
    try {
      const response = await RoteirizadorAPI.post(
        `api/v1/motorista/Veiculo/padrao`,
        body
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const DeletarVeiculoMotorista = createAsyncThunk(
  'clientsData/DeletarVeiculoMotorista',
  async (
    args: {
      idMotorista: number;
      idVeiculo: number;
    },
    { rejectWithValue }
  ): Promise<IVeiculoMotoristaResponse | any> => {
    try {
      const response = await RoteirizadorAPI.delete(
        `api/v1/motorista/Veiculo?idMotorista=${args.idMotorista}&idVeiculo=${args.idVeiculo}`
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterTabelaPreco = createAsyncThunk(
  'clientsData/ObterTabelaPreco',
  async (
    _,
    { rejectWithValue }
  ): Promise<IConsultaTabelaPrecoResponse | any> => {
    try {
      const response = await RoteirizadorAPI.get(`api/v1/Tabela/Preco`);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const CadastrarTabelaPreco = createAsyncThunk(
  'clientsData/CadastrarTabelaPreco',
  async (
    args: {
      body: ICadastraTabelaPrecoRequest;
    },
    { rejectWithValue }
  ): Promise<ITabelaPrecoResponse | any> => {
    const { body } = args;

    try {
      const response = await RoteirizadorAPI.post(`api/v1/Tabela/Preco`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const EditarTabelaPreco = createAsyncThunk(
  'clientsData/EditarTabelaPreco',
  async (
    args: {
      body: IEditaTabelaPrecoRequest;
    },
    { rejectWithValue }
  ): Promise<ITabelaPrecoResponse | any> => {
    const { body } = args;

    try {
      const response = await RoteirizadorAPI.put(`api/v1/Tabela/Preco`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterVeiculo = createAsyncThunk(
  'clientsData/ObterVeiculo',
  async (_, { rejectWithValue }): Promise<IConsultaVeiculoResponse | any> => {
    try {
      const response = await RoteirizadorAPI.get(`api/v1/veiculo`);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const CadastrarVeiculo = createAsyncThunk(
  'clientsData/CadastrarVeiculo',
  async (
    args: {
      body: ICadastraVeiculoRequest;
    },
    { rejectWithValue }
  ): Promise<IVeiculoResponse | any> => {
    const { body } = args;
    try {
      const response = await RoteirizadorAPI.post(`api/v1/veiculo`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const EditarVeiculo = createAsyncThunk(
  'clientsData/EditarVeiculo',
  async (
    args: {
      body: IEditaVeiculoRequest;
    },
    { rejectWithValue }
  ): Promise<IVeiculoResponse | any> => {
    const { body } = args;
    try {
      const response = await RoteirizadorAPI.put(`api/v1/veiculo`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterTipoVeiculo = createAsyncThunk(
  'clientsData/ObterTipoVeiculo',
  async (_, { rejectWithValue }): Promise<IConsultaVeiculoResponse | any> => {
    try {
      const response = await RoteirizadorAPI.get(`api/v1/veiculo/tipo`);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const CadastrarTipoVeiculo = createAsyncThunk(
  'clientsData/CadastrarTipoVeiculo',
  async (
    args: {
      body: ICadastraTipoVeiculoRequest;
    },
    { rejectWithValue }
  ): Promise<ITipoVeiculoResponse | any> => {
    const { body } = args;

    try {
      const response = await RoteirizadorAPI.post(`api/v1/veiculo/tipo`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const EditarTipoVeiculo = createAsyncThunk(
  'clientsData/EditarTipoVeiculo',
  async (
    args: {
      body: IEditaTipoVeiculoRequest;
    },
    { rejectWithValue }
  ): Promise<ITipoVeiculoResponse | any> => {
    const { body } = args;

    try {
      const response = await RoteirizadorAPI.put(`api/v1/veiculo/tipo`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const ObterPedidos = createAsyncThunk(
  'clientsData/ObterPedidos',
  async (
    args: {
      idDeposito: string;
      dataInicio: string;
      dataFim: string;
      searchType: '0' | '1';
      onlyOk: boolean;
    },
    { rejectWithValue }
  ): Promise<IPedidoV2[] | any> => {
    try {
      const query = new URLSearchParams({
        idDeposito: args.idDeposito,
        dataInicio: args.dataInicio,
        dataFim: args.dataFim,
        tipoIntervalo: args.searchType,
        onlyOk: args.onlyOk ? 'true' : 'false',
      }).toString();

      const response = await OrderAPI.get(
        `api/v1/orders/${args.idDeposito}?${query}`
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const CalculaDetalhesRota = createAsyncThunk(
  'clientsData/CalculaDetalhesRota',
  async (
    args: {
      body: IDetalheRotaRequest;
    },
    { rejectWithValue }
  ): Promise<IRota | any> => {
    const { body } = args;

    try {
      const response = await OrderAPI.post(`api/v1/routes/detail`, body);

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const CadastraCTE = createAsyncThunk(
  'clientsData/CadastraCTE',
  async (args: { file: File }, { rejectWithValue }): Promise<any> => {
    try {
      const body = {
        base64: await convertFileToBase64(args.file),
      };

      const response = await OrderAPI.post(`api/v1/CTE`, body);

      if (response.data?.error !== undefined) return response.data;
      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

// User Wharehouse
export const putWarehouseExchange = createAsyncThunk(
  'clientsData/putWarehouseExchange',
  async (
    args: {
      body: IPutTrocarDepositoRequest;
    },
    { rejectWithValue }
  ): Promise<ITrocaDepositoResponse | any> => {
    const { body } = args;
    try {
      const response = await CustomerAPI.put(
        `/api/v1/customer/User/warehouse/access`,
        body
      );

      return response.data;
    } catch (err: any) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);
