import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { clearSelectCheckedOrders } from '../../../../redux/features/generalData/generalDataSlice';
import { useAsyncDispatch } from '../../../../redux/store';
import { ICheckOrderResponse } from '../../../CreateRoute/interfaces/Rota/ICheckOrderResponse';
import usePrintedOrderTable from './hooks/usePrintedOrderTable';

export interface IPrintedOrderTable {
  printedOrders: ICheckOrderResponse[];
  onPrintOrder: (order: ICheckOrderResponse) => void;
}

const PrintedOrderTable: React.FC<IPrintedOrderTable> = ({
  onPrintOrder,
  printedOrders,
}): JSX.Element => {
  const { cellData, columns, handleRowClick } = usePrintedOrderTable({
    onPrintOrder,
    printedOrders,
  });

  const dispatch = useAsyncDispatch();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        gap: 8,
      }}
    >
      <Button
        color="danger"
        onClick={() => dispatch(clearSelectCheckedOrders())}
      >
        Limpar
      </Button>

      <Table
        aria-label="Etiquetas impressas"
        selectionMode="single"
        onRowAction={handleRowClick}
      >
        <TableHeader columns={columns}>
          {({ key, name }) => <TableColumn key={key}>{name}</TableColumn>}
        </TableHeader>

        <TableBody
          items={printedOrders}
          emptyContent="Nenhuma etiqueta impressa"
        >
          {(item) => (
            <TableRow
              key={`${item.rotaPedido.idRotaPedido}${item.rotaPedido.volumesConferidos}`}
            >
              {(columnKey) => (
                <TableCell>
                  <p className="text-foreground-600">
                    {cellData(item, columnKey as any)}
                  </p>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default PrintedOrderTable;
