import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React from 'react';
import { IRotaPedido } from '../../../../CreateRoute/interfaces/Rota/IRotaPedido';
import useOrderListTable from './hook/useOrderListTable';

export interface IOrderListTable {
  orders: IRotaPedido[];
  onSelectionChange: (keys: any) => Promise<void>;
}

const OrderListTable: React.FC<IOrderListTable> = ({
  orders,
  onSelectionChange,
}): JSX.Element => {
  const { cellData, columns, setSortDescriptor, sortDescriptor, sortedOrders } =
    useOrderListTable({
      orders,
    });

  return (
    <Table
      aria-label="Tabela da rota selecionada"
      isHeaderSticky
      selectionMode="multiple"
      onSelectionChange={onSelectionChange}
      classNames={{
        wrapper: 'max-h-[50vh]',
      }}
      removeWrapper
      className="p-4"
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
    >
      <TableHeader columns={columns}>
        {({ key, name }) => (
          <TableColumn key={key} allowsSorting>
            {name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody items={sortedOrders} emptyContent="Nenhuma rota no momento">
        {(item) => (
          <TableRow key={item.idRotaPedido}>
            {(columnKey) => (
              <TableCell>
                <p className="text-foreground-600">
                  {cellData(item, columnKey as any)}
                </p>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default OrderListTable;
