import { Key, useCallback } from 'react';
import { ICheckOrderResponse } from '../../../../CreateRoute/interfaces/Rota/ICheckOrderResponse';

type Column = {
  key: any;
  name: string;
};

export interface UsePrintedOrderTableProps {
  printedOrders: ICheckOrderResponse[];
  onPrintOrder: (order: ICheckOrderResponse) => void;
}

export interface UsePrintedOrderTable {
  handleRowClick: (key: Key) => void;
  columns: Column[];
  cellData: (
    order: ICheckOrderResponse,
    columnKey: any
  ) => number | string | JSX.Element;
}

const usePrintedOrderTable = ({
  printedOrders,
  onPrintOrder,
}: UsePrintedOrderTableProps): UsePrintedOrderTable => {
  const columns: Column[] = [
    {
      key: 'ID',
      name: 'ID',
    },
    {
      key: 'ROTA',
      name: 'ROTA',
    },
    {
      key: 'ORDEM',
      name: 'ORDEM',
    },
    {
      key: 'DANFE',
      name: 'DANFE',
    },
    {
      key: 'VOLUME ETIQUETADO',
      name: 'VOLUME ETIQUETADO',
    },
    {
      key: 'VOLUME TOTAL',
      name: 'VOLUME TOTAL',
    },
    {
      key: 'NR NF',
      name: 'NR NF',
    },
    {
      key: 'SERIE NF',
      name: 'SERIE NF',
    },
  ];

  const cellData = useCallback(
    (
      order: ICheckOrderResponse,
      columnKey: any
    ): number | string | JSX.Element => {
      switch (columnKey) {
        case 'ID':
          return order.pedido.idPedido;
        case 'DANFE':
          return order.pedido.chaveAcessoNF;
        case 'VOLUME ETIQUETADO':
          return order.rotaPedido.volumesConferidos;
        case 'VOLUME TOTAL':
          return order.pedido.quantidadeVolumes;
        case 'NR NF':
          return `${order.pedido.nf}`;
        case 'SERIE NF':
          return `${order.pedido.serieNf}`;
        case 'ROTA':
          return `${order.rota.descricao.toUpperCase()}`;
        case 'ORDEM':
          return `${order.rotaPedido.ordem.toString()}`;
        default:
          return '';
      }
    },
    []
  );

  const handleRowClick = (key: Key): void => {
    const order = printedOrders.find(
      (orderAux) =>
        `${orderAux.rotaPedido.idRotaPedido}${orderAux.rotaPedido.volumesConferidos}` ===
        key
    );

    if (order) onPrintOrder(order);
  };

  return {
    cellData,
    columns,
    handleRowClick,
  };
};

export default usePrintedOrderTable;
