import React, { FC } from 'react';
import { Deposito } from '../../../../interfaces/IUsuario';
import { IPedido } from '../../interfaces/IPedido';
import { IRotaDetalhe } from '../../interfaces/Rota/IRotaDetalhe';
import OrderLocations from '../OrderLocations/OrderLocations';
import useCreateRouteMap from './hooks/useCreateRouteMap';

export interface CreateRouteMapProps {
  orders: IPedido[];
  toggleOrderSelection: (order: IPedido) => void;
  toggleOrderSelectionManyAdd: (orders: IPedido[]) => void;
  toggleOrderSelectionManyDelete: (orders: IPedido[]) => void;
  selectedOrders: IPedido[];
  warehouse: Deposito | undefined;
  routeDetails: IRotaDetalhe | undefined;
  strokeColor: string;
}

const CreateRouteMap: FC<CreateRouteMapProps> = ({
  orders,
  selectedOrders,
  toggleOrderSelection,
  toggleOrderSelectionManyAdd,
  toggleOrderSelectionManyDelete,
  warehouse,
  routeDetails,
  strokeColor,
}): JSX.Element => {
  const { map, mapContainerRef } = useCreateRouteMap({ warehouse });

  return (
    <>
      {/* Map Container */}
      <div
        ref={mapContainerRef}
        style={{
          height: 'calc(100vh - 65px)',
        }}
        className="w-full"
      />

      {/* Location Markers */}
      {map && (
        <OrderLocations
          strokeColor={strokeColor}
          routeDetails={routeDetails}
          map={map}
          orders={orders}
          warehouse={warehouse}
          toggleOrderSelection={toggleOrderSelection}
          toggleOrderSelectionManyAdd={toggleOrderSelectionManyAdd}
          toggleOrderSelectionManyDelete={toggleOrderSelectionManyDelete}
          selectedOrders={selectedOrders}
        />
      )}
    </>
  );
};

export default CreateRouteMap;
