import {
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React, { useMemo, useState } from 'react';
import {
  formatarMedidaGenerico,
  formatarMoeda,
} from '../../../../../util/mask';
import {
  GetRotaPedidoObj,
  IRotaPedido,
} from '../../../../CreateRoute/interfaces/Rota/IRotaPedido';

const PedidosTable = ({ pedidos }: { pedidos: IRotaPedido[] }): JSX.Element => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'Ordem de entrega',
    direction: 'descending',
  });

  const columns: any[] = [
    {
      key: 'Ordem',
      name: 'ORDEM',
      size: 30,
    },
    {
      key: 'Valor',
      name: 'VALOR',
      size: 30,
    },
    {
      key: 'Peso',
      name: 'PESO REAL',
      size: 30,
    },
    {
      key: 'Volume',
      name: 'VOLUMES',
      size: 30,
    },
    {
      key: 'Endereço',
      name: 'ENDEREÇO',
      size: 200,
    },
    {
      key: 'Numero',
      name: 'NÚMERO',
      size: 200,
    },
    {
      key: 'Bairro',
      name: 'BAIRRO',
      size: 200,
    },
    {
      key: 'Cidade',
      name: 'CÍDADE',
      size: 200,
    },
  ];

  const cellData = (order: IRotaPedido, columnKey: any): number | string => {
    const pedido = GetRotaPedidoObj(order);

    switch (columnKey) {
      case 'Ordem':
        return order.ordem;
      case 'Valor':
        return formatarMoeda(pedido.valorTotalNf);
      case 'Peso':
        return formatarMedidaGenerico(pedido.valorPesoTotal || 0, 'Kg');
      case 'Volume':
        return pedido.quantidadeVolumes;
      case 'Endereço':
        return pedido.destinatarioLogradouro;
      case 'Numero':
        return pedido.destinatarioNumero;
      case 'Bairro':
        return pedido.destinatarioBairro;
      case 'Cidade':
        return pedido.destinatarioCidade;
      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return [...pedidos].sort((a: IRotaPedido, b: IRotaPedido) => {
      const first = cellData(a, sortDescriptor.column as any) as number;
      const second = cellData(b, sortDescriptor.column as any) as number;

      let cmp = 0;
      if (first < second) cmp = -1;
      if (first > second) cmp = 1;

      return sortDescriptor.direction === 'ascending' ? -cmp : cmp;
    });
  }, [sortDescriptor, pedidos]);

  return (
    <div>
      <Table
        aria-label="Tabela da rota selecionada"
        isHeaderSticky
        selectionMode="single"
        classNames={{
          wrapper: 'max-h-[50vh]',
        }}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={columns}>
          {({ key, name, size }) => (
            <TableColumn align="center" width={size} key={key} allowsSorting>
              {name}
            </TableColumn>
          )}
        </TableHeader>

        <TableBody items={sortedOrders} emptyContent="Nenhuma rota no momento">
          {(item) => (
            <TableRow key={item.idRotaPedido}>
              {(columnKey) => (
                <TableCell>
                  <p className="text text-small text-foreground-600">
                    {cellData(item, columnKey as any)}
                  </p>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default PedidosTable;
