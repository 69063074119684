/* eslint-disable react/no-array-index-key */
import { Select, SelectItem, Spinner } from '@nextui-org/react';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import DatePickerInput from '../../../components/DatepickerInput/DatepickerInput';
import ModalDetalhePedido from './components/ModalDetalhePedido/ModalDetalhePedido';
import OrderListTable from './components/OrderListTable/OrderListTable';
import usePedidos from './hooks/usePedidos';
import { IPedido } from './interfaces/IPedido';

const Pedidos = (): JSX.Element => {
  const {
    loading,
    pedidos,
    control,
    endDate,
    startDate,
    searchType,
    theme,
    handleSearchTypeSelected,
    openModal,
    setOpenModal,
  } = usePedidos();

  const [selectedOrder, setSelectedOrder] = useState<IPedido | undefined>(
    undefined
  );

  const handleOpenModal = (pedido: IPedido): void => {
    setSelectedOrder(pedido);
    setOpenModal(true);
  };
  const handleCloseModal = (): void => {
    setSelectedOrder(undefined);
    setOpenModal(false);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-between">
          <ModalDetalhePedido
            isOpen={openModal}
            handleClose={() => handleCloseModal()}
            pedido={selectedOrder}
            volumes={selectedOrder?.volumes || []}
          />
          <div className="flex flex-row gap-4" style={{ marginRight: 16 }}>
            <Controller
              control={control}
              name="searchType"
              render={({ field }) => (
                <Select
                  {...field}
                  label="Tipo de Filtro"
                  variant="flat"
                  size="md"
                  placeholder="Selecione uma data para realizar o filtro de CTEs"
                  classNames={{
                    label: 'font-bold',
                    popoverContent: `${theme}`,
                  }}
                  selectedKeys={searchType}
                  onBlur={field.onBlur}
                  className={`${theme}`}
                  onSelectionChange={(keys) => {
                    field.onChange(keys);
                    handleSearchTypeSelected(keys);
                  }}
                  style={{ width: 350 }}
                >
                  <SelectItem key={0} value="0" textValue="Data de cadastro">
                    <p className="text-foreground-700">Data de cadastro</p>
                  </SelectItem>
                  <SelectItem
                    key={1}
                    value="1"
                    textValue="Data previsão de entrega"
                  >
                    <p className="text-foreground-700">
                      Data previsão de entrega
                    </p>
                  </SelectItem>
                </Select>
              )}
            />
          </div>
          {/* Date Filter */}
          <div className="flex flex-row gap-4">
            <DatePickerInput
              control={control}
              name="startDate"
              datePickerProps={{
                selectsStart: true,
                startDate,
                endDate,
                maxDate: endDate,
              }}
              inputProps={{
                size: 'md',
                label: 'de',
                variant: 'flat',
              }}
            />

            <DatePickerInput
              control={control}
              name="endDate"
              datePickerProps={{
                selectsEnd: true,
                startDate,
                endDate,
                minDate: startDate,
              }}
              inputProps={{
                size: 'md',
                label: 'até',
                variant: 'flat',
              }}
            />
          </div>
        </div>
      </div>

      {/* Spinner */}
      {loading && (
        <Spinner label="Baixando conteúdo" color="default" className="w-full" />
      )}

      {/* Table */}
      {!loading && (
        <OrderListTable
          setOpenModalDetails={handleOpenModal}
          orders={pedidos}
        />
      )}
    </div>
  );
};

export default Pedidos;
