import { useDisclosure } from '@nextui-org/react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useContext, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  Theme,
  ThemeContext,
} from '../../../../../../contexts/ThemeContext/ThemeContext';
import { Context } from '../../../../../../layouts/GerirRotasLayout/GerirRotasLayout';
import { selectUsuario } from '../../../../../../redux/features/generalData/generalDataSelectors';
import { postRouteToLeave } from '../../../../../../redux/features/gerirRotas/gerirRotasThunk';
import { useAsyncDispatch } from '../../../../../../redux/store';
import { IRota } from '../../../../../CreateRoute/interfaces/Rota/IRota';

interface IUseRouteOrderListModal {
  isOpen: boolean;
  onOpenChange: () => void;
  theme: Theme;
  route: IRota | null;
  handleOpenModal: (selectedRoute: IRota) => void;
  handleCloseModal: () => void;
  handleApproveRoute: () => Promise<void>;
  loadingApproval: boolean;
}

export const useRouteOrderListModal = (): IUseRouteOrderListModal => {
  const dispatch = useAsyncDispatch();
  const user = useSelector(selectUsuario);
  const { refreshRoutes } = useContext(Context);
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const { theme } = useContext(ThemeContext);
  const cancelTokenRef = useRef<CancelTokenSource>();
  const cancelToken = axios.CancelToken;
  const [route, setRoute] = useState<IRota | null>(null);
  const [loadingApproval, setLoadingApproval] = useState<boolean>(false);

  const handleOpenModal = (selectedRoute: IRota): void => {
    onOpen();
    setRoute(selectedRoute);
  };

  const handleCloseModal = (): void => {
    onClose();
    refreshRoutes();
    setRoute(null);
  };

  const handleApproveRoute = async (): Promise<void> => {
    if (!route) return;

    setLoadingApproval(true);
    const source: CancelTokenSource = cancelToken.source();
    cancelTokenRef.current = source;

    await dispatch(
      postRouteToLeave({
        body: {
          idRota: route.idRota,
          idDeposito: user?.defaultWarehouse.idDeposito || 0,
          nomeUsuario: user?.name || '',
        },
        cancelToken: source,
      })
    )
      .unwrap()
      .then(handleCloseModal)
      .catch((error) => {
        const axiosError = error as AxiosError;
        if (axiosError.code === 'ERR_CANCELED') return;

        toast.error('Erro ao aprovar rota, por favor tente mais tarde');
      })
      .finally(() => setLoadingApproval(false));
  };

  return {
    isOpen,
    onOpenChange,
    theme,
    route,
    handleOpenModal,
    handleCloseModal,
    handleApproveRoute,
    loadingApproval,
  };
};
