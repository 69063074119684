export enum ESituacaoPedido {
  GEOLOCALIZACAO = 0,
  OK = 1,
  ERRO = 2,
  ENTREGUE = 3,
  CANCELADO = 4,
  EM_ROTA = 5,
}

export const GetDescription = (status: ESituacaoPedido | undefined): string => {
  switch (status) {
    case ESituacaoPedido.GEOLOCALIZACAO:
      return 'Aguardando Geolocalização';
    case ESituacaoPedido.OK:
      return 'OK';
    case ESituacaoPedido.ERRO:
      return 'Erro';
    case ESituacaoPedido.ENTREGUE:
      return 'Entregue';
    case ESituacaoPedido.CANCELADO:
      return 'Cancelado';
    case ESituacaoPedido.EM_ROTA:
      return 'Em Rota';
    default:
      return 'Não Identificado';
  }
};
