import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { IMotorista } from '../../../../interfaces/response/Motorista/IMotorista';
import { IVeiculo } from '../../../../interfaces/response/Veiculo/IVeiculo';
import {
  ObterMotorista,
  ObterVinculoVeiculoMotorista,
} from '../../../../redux/features/clientsData/clientsDataThunk';
import { selectUsuario } from '../../../../redux/features/generalData/generalDataSelectors';
import {
  approveRoute,
  getRouteDetails,
  rejectRoute,
} from '../../../../redux/features/rotas/rotasThunk';
import { useAsyncDispatch } from '../../../../redux/store';
import { IRota } from '../../../CreateRoute/interfaces/Rota/IRota';

interface UseDetalheRotaProps {
  route: IRota | undefined;
  loading: boolean;
  handleApproveRoute: (idRota: number) => Promise<void>;
  loadingApproval: boolean;
  handleRejectRoute: (idRota: number) => Promise<void>;
  loadingReject: boolean;
  veiculo: IVeiculo | undefined;
  motorista: IMotorista | undefined;
}

const UseDetalheRota = (): UseDetalheRotaProps => {
  const { id: routeId } = useParams();
  const user = useSelector(selectUsuario);
  const [route, setRoute] = useState<IRota>();
  const [veiculo, setVeiculo] = useState<IVeiculo>();
  const [motorista, setMotorista] = useState<IMotorista>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingApproval, setLoadingApproval] = useState<boolean>(false);
  const [loadingReject, setLoadingReject] = useState<boolean>(false);
  const dispatch = useAsyncDispatch();
  const navigate = useNavigate();

  const getRoute = async (): Promise<void> => {
    if (!routeId) return;

    setLoading(true);
    await dispatch(
      getRouteDetails({
        id: Number(routeId),
        idDeposito: user?.defaultWarehouse.idDeposito || 0,
      })
    )
      .unwrap()
      .then((res) => {
        setRoute(res.rota);
        getVeiculoMotorista(res.rota.idVeiculoMotorista);
        toast.success('rota consultada com sucesso');
      })
      .catch((err) => {
        toast.success('erro ao consultar a rota');
      })
      .finally(() => setLoading(false));
  };

  const getVeiculoMotorista = async (
    idVeiculoMotorista: number
  ): Promise<void> => {
    if (!idVeiculoMotorista) return;

    setLoading(true);
    await dispatch(
      ObterVinculoVeiculoMotorista({
        idVeiculoMotorista,
        idDeposito: user?.defaultWarehouse.idDeposito || 0,
      })
    )
      .unwrap()
      .then((res) => {
        setVeiculo(res.veiculoMotorista.veiculo);
        getMotorista(res.veiculoMotorista.idMotorista);
      })
      .catch((err) => {
        toast.success('erro ao consultar o veículo');
      })
      .finally(() => setLoading(false));
  };

  const getMotorista = async (idMotorista: number): Promise<void> => {
    if (!idMotorista) return;

    setLoading(true);
    await dispatch(
      ObterMotorista({
        idMotorista,
      })
    )
      .unwrap()
      .then((res) => {
        setMotorista(res.motorista);
      })
      .catch((err) => {
        toast.success('erro ao consultar o veículo');
      })
      .finally(() => setLoading(false));
  };

  const handleApproveRoute = async (): Promise<void> => {
    if (!routeId) return;

    setLoadingApproval(true);
    await dispatch(
      approveRoute({
        id: Number(routeId) || 0,
        idDeposito: user?.defaultWarehouse.idDeposito || 0,
        userName: user?.name || '',
      })
    )
      .unwrap()
      .then(async (res) => {
        toast.success('rota aprovada com sucesso');
        navigate('rotas/aguardando-aprovacao');
      })
      .catch((err) => {
        toast.error('erro ao aprovar a rota');
      })
      .finally(() => setLoadingApproval(false));
  };

  const handleRejectRoute = async (): Promise<void> => {
    if (!routeId) return;

    setLoadingReject(true);
    await dispatch(
      rejectRoute({
        id: Number(routeId) || 0,
        idDeposito: user?.defaultWarehouse.idDeposito || 0,
        userName: user?.name || '',
      })
    )
      .unwrap()
      .then((res) => {
        toast.success('rota cancelada com sucesso');

        navigate('rotas/aguardando-aprovacao');
      })
      .catch((err) => {
        toast.error('erro ao cancelar a rota');
      })
      .finally(() => setLoadingReject(false));
  };

  useEffect(() => {
    getRoute();
  }, []);

  return {
    route,
    loading,
    handleApproveRoute,
    handleRejectRoute,
    loadingApproval,
    loadingReject,
    veiculo,
    motorista,
  };
};

export default UseDetalheRota;
