import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrderAPI } from '../../../config/api';
import { IBadRequestResponse } from '../../../interfaces/IBadRequestResponse';
import { IDetalheRotaRequest } from '../../../interfaces/request/DetalheRota/IDetalheRotaRequest';

export const postRoute = createAsyncThunk(
  'rotas/postRoute',
  async (
    args: {
      body: IDetalheRotaRequest;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { body } = args;

    try {
      const { data } = await OrderAPI.post(`api/v1/routes`, body);

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const getRouteDetails = createAsyncThunk(
  'rotas/getRouteDetails',
  async (
    args: {
      id: number;
      idDeposito: number;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { id, idDeposito } = args;

    try {
      const { data } = await OrderAPI.get(
        `api/v1/routes/${id}/warehouse/${idDeposito}`
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const approveRoute = createAsyncThunk(
  'rotas/approveRoute',
  async (
    args: {
      id: number;
      idDeposito: number;
      userName: string;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { id, idDeposito, userName } = args;

    try {
      const { data } = await OrderAPI.post(`api/v1/routes/approve`, {
        idRota: id,
        idDeposito,
        nomeUsuario: userName,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);

export const rejectRoute = createAsyncThunk(
  'rotas/rejectRoute',
  async (
    args: {
      id: number;
      idDeposito: number;
      userName: string;
    },
    { rejectWithValue }
  ): Promise<any> => {
    const { id, idDeposito, userName } = args;

    try {
      const { data } = await OrderAPI.post(`api/v1/routes/reprove`, {
        idRota: id,
        idDeposito,
        nomeUsuario: userName,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IBadRequestResponse>;

      return rejectWithValue(error);
    }
  }
);
