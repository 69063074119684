import React, { FC } from 'react';
import { formatarData } from '../../../../util/format';
import { moneyMask } from '../../../../util/mask';
import { IPedido } from '../../interfaces/IPedido';

export interface TootipCardProps {
  order: IPedido;
}

const TootipCard: FC<TootipCardProps> = ({ order }): JSX.Element => {
  return (
    <div className="p-0.5">
      {/* ID */}
      <p className="mb-1 text-xs ">
        <span className="font-bold ">Número NF: </span>
        {order.nf} - {order.serieNf}
      </p>

      {/* Volumes */}
      <p className="text-xs ">
        <span className="font-semibold">Volumes: </span>
        {order.quantidadeVolumes}
      </p>

      {/* Value */}
      <p className="text-xs ">
        <span className="font-semibold">Valor NF: </span>
        {moneyMask(order.valorTotalNf, 'R$', 2)}
      </p>

      {/* Delivery date */}
      <p className="text-xs ">
        <span className="font-semibold">Entrega até: </span>
        {formatarData(order.dataPrevisaoEntrega, true, 'normal')}
      </p>
    </div>
  );
};

export default TootipCard;
