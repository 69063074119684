import { Input } from '@nextui-org/react';
import React from 'react';
import { formatarCEP, mascararDocumento } from '../../../../../../../util/mask';
import { IPedido } from '../../../../interfaces/IPedido';

interface ITabProps {
  pedido: IPedido | undefined;
}

const TabDetalheDestinatario = ({ pedido }: ITabProps): JSX.Element => {
  return (
    <div className="flex w-full flex-row gap-2">
      <div className="flex w-full flex-col gap-2">
        {/* Linha 1: Nome e Email */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Nome"
              value={pedido?.destinatarioNome}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="md"
              label={
                pedido?.destinatarioDocumento &&
                pedido?.destinatarioDocumento.length > 14
                  ? 'CNPJ'
                  : 'CPF'
              }
              value={
                pedido?.destinatarioDocumento &&
                pedido?.destinatarioDocumento.length > 14
                  ? mascararDocumento(2, pedido?.destinatarioDocumento || '')
                  : mascararDocumento(1, pedido?.destinatarioDocumento || '')
              }
              disabled
            />
          </div>
        </div>

        {/* Linha 2: CNPJ e CPF */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="w-3/4 flex-1">
            <Input
              size="md"
              label="Logradouro"
              value={pedido?.destinatarioLogradouro}
              disabled
            />
          </div>

          <div className=" w-1/4">
            <Input
              size="md"
              label="Número"
              value={pedido?.destinatarioNumero}
              disabled
            />
          </div>
        </div>

        {/* Linha 4: Numero, Complementeo e Bairro */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="CEP"
              value={formatarCEP(pedido?.destinatarioCep || '')}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="md"
              label="Bairro"
              value={pedido?.destinatarioBairro}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="md"
              label="Cidade"
              value={pedido?.destinatarioCidade}
              disabled
            />
          </div>

          <div className="flex-1">
            <Input
              size="md"
              label="Estado"
              value={pedido?.destinatarioCidade}
              disabled
            />
          </div>
        </div>

        {/* Linha 5: Cidade, Estado, CEP */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Complemento"
              value={pedido?.destinatarioComplemento}
              disabled
            />
          </div>
        </div>
        {/* Linha 3: Logradouro, Número, Complemento */}
        <div className="flex w-full flex-wrap gap-2">
          <div className="flex-1">
            <Input
              size="md"
              label="Latitude"
              value={pedido?.destinatarioLatitude?.toString()}
              disabled
            />
          </div>
          <div className="flex-1">
            <Input
              size="md"
              label="Longitude"
              value={pedido?.destinatarioLongitude?.toString()}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabDetalheDestinatario;
